import type { CacheExchangeOpts } from "@urql/exchange-graphcache";
import type { AnyVariables, CombinedError, TypedDocumentNode } from "urql";

import type { UrqlClient } from "../../remote/createUrqlClient";
import { ClientError, graphqlErrorToClientError } from "../error";
import type { LoggerType } from "../log";

export interface AlaffiaClientModuleArgs {
  apiUrl: string;
  gqlClient: UrqlClient;
  logger: LoggerType;
}

interface GraphQLResult<TResult, TError = CombinedError> {
  data: TResult | null;
  error: TError | null;
}

export abstract class AlaffiaClientModule {
  protected apiUrl: string;
  protected gqlClient: UrqlClient;
  protected logger: LoggerType;

  constructor(args: AlaffiaClientModuleArgs) {
    this.apiUrl = args.apiUrl;
    this.gqlClient = args.gqlClient;
    this.logger = args.logger;
  }

  static cacheOptions: CacheExchangeOpts["updates"] = {};

  protected getClientError = (contextMessage: string, error: unknown) => {
    const originalMessage =
      error instanceof Error
        ? error.message
        : error?.toString() ?? "Unknown error";
    const msg = `${contextMessage}: ${originalMessage}`;
    this.logger.error(msg, { error });
    return new ClientError(msg, { error });
  };

  protected runGqlQuery = async <TResult, TVariables extends AnyVariables>(
    query: TypedDocumentNode<TResult, TVariables>,
    variables: TVariables,
    queryName: string,
  ): Promise<GraphQLResult<TResult>> => {
    const result = await this.gqlClient.query(query, variables);
    this.logger.log(queryName, "result", result);
    if (result.error && !result.data) {
      const error = graphqlErrorToClientError(result.error);

      this.logger.log(queryName, "rejecting on full result error", {
        error,
        result,
      });
      throw error;
    }

    return {
      data: result.data ?? null,
      error: result.error ?? null,
    };
  };

  protected runGqlMutation = async <TResult, TVariables extends AnyVariables>(
    mutation: TypedDocumentNode<TResult, TVariables>,
    variables: TVariables,
    mutationName: string,
  ): Promise<GraphQLResult<TResult>> => {
    const result = await this.gqlClient.mutation(mutation, variables);
    this.logger.log(mutationName, "result", result);
    if (result.error && !result.data) {
      const error = graphqlErrorToClientError(result.error);

      this.logger.log(mutationName, "rejecting on full result error", {
        error,
        result,
      });
      throw error;
    }

    return {
      data: result.data ?? null,
      error: result.error ?? null,
    };
  };
}
