/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useCallback, useContext, useMemo } from "react";
import { AuditOutlined, CalendarTwoTone } from "@ant-design/icons";
import {
  Badge,
  Button,
  Col,
  Row,
  Space,
  Statistic,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import { useRouteMatch } from "react-router-dom";

import {
  SplitView,
  SplitViewProvider,
} from "@alaffia-technology-solutions/components";

import { useHashParams } from "../../../common/navigation/useHashParam";
import { UserContext } from "../../context/user";
import { ReportsVersionList } from "../../reports/reportsVersionList";
import { RefreshBatchClaimContext } from "../auditWorkspace";
import { ViewDocumentsButton } from "../documents/viewDocumentsButton";
import { dateString } from "../util";
import { UBClaimAuditFinding } from "./auditFinding/ubClaimAuditFinding";
import { ClaimWorkspaceHeader } from "./claimWorkspaceHeader";
import { ClaimWorkspaceTable } from "./claimWorkspaceTable";
import { CreateAuditFindingModal } from "./createAuditFinding/createAuditFindingModal";
import { DeclineAuditFindingModal } from "./createAuditFinding/declineAuditFindingModal";
import { DrgReviewDataDefault, DrgTab } from "./drg/drgTab";
import { FindingsTable } from "./findingsTab/findingsTable";
import { IbTab } from "./itemizedBillTab/ibTab";
import { ItemizedBillTable } from "./itemizedBillTab/itemizedBillTable";
import { ViewDocumententationModal } from "./viewDocumententationModal";

const PureClaimWorkspace = (props: any) => {
  const { batchClaim } = props; // todo rewire style prop?
  const ctxRefresh = useContext(RefreshBatchClaimContext);
  const user = React.useContext(UserContext);
  const batchClaimId = batchClaim.id;
  const afclFilter = {
    isActive: { equalTo: true },
  };

  const {
    batchClaimState: { workflowClaimState },
    auditDocumentation: files,
    ubClaimFindings,
  } = batchClaim || {
    auditDocumentation: [],
  };

  const viewingReport =
    useRouteMatch("/reports/:auditFindingReportId/workspace") !== null;

  const [creatingAuditFinding, setCreatingAuditFinding] = React.useState({
    visible: false,
    auditFindingSeedType: null,
  });
  const [decliningAuditFinding, setDecliningAuditFinding] = React.useState({
    visible: false,
  });

  const [viewDocumentNamesModalVisible, setViewDocumentNamesModalVisible] =
    React.useState(false);
  const [viewingClaimLevelFinding, setViewingClaimLevelFinding] =
    React.useState(false);

  // drg screen variables:
  const [drgReviewData, setDrgReviewData] =
    React.useState(DrgReviewDataDefault);

  const readOnly = workflowClaimState !== "IN_PROGRESS" || viewingReport;

  const renderViewDocumentsButton = () => (
    <ViewDocumentsButton claimId={batchClaimId}></ViewDocumentsButton>
  );

  const renderClaimFindingToggle = () =>
    viewingClaimLevelFinding ? (
      <Button
        id="create-ub04-claim-audit-finding"
        data-cy="claim-level-audit-finding-hide"
        type="primary"
        icon={<AuditOutlined />}
        css={{
          padding: "0 7px",
          fontSize: "12px",
          lineHeight: "20px",
          height: "auto",
          marginLeft: "5px",
        }}
        onClick={() => setViewingClaimLevelFinding(false)}
      >
        {viewingClaimLevelFinding ? "View Claim Info" : "View Claim Finding"}
      </Button>
    ) : (
      <Badge count={ubClaimFindings.nodes.length} size="small">
        <Button
          id="create-ub04-claim-audit-finding"
          data-cy="claim-level-audit-finding-show"
          type="primary"
          icon={<AuditOutlined />}
          css={{
            padding: "0 7px",
            fontSize: "12px",
            lineHeight: "20px",
            height: "auto",
            marginLeft: "5px",
          }}
          onClick={() => setViewingClaimLevelFinding(true)}
        >
          {viewingClaimLevelFinding ? "View Claim Info" : "View Claim Finding"}
        </Button>
      </Badge>
    );

  const renderClaimAuditFindingButton = () =>
    ubClaimFindings.nodes.length > 0 ? (
      renderClaimFindingToggle()
    ) : (
      <Button
        id="create-ub04-claim-audit-finding"
        data-cy="claim-level-audit-finding-create"
        type="primary"
        icon={<AuditOutlined />}
        css={{
          padding: "0 7px",
          fontSize: "12px",
          lineHeight: "20px",
          height: "auto",
          marginLeft: "5px",
        }}
        disabled={readOnly}
        onClick={() =>
          setCreatingAuditFinding({
            visible: true,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            auditFindingSeedType: "UB_CLAIM",
          })
        }
      >
        Claim Level Finding
      </Button>
    );

  const renderClaimInfo = (_userType: any) => (
    <div tw="af-pr-6">
      <div tw="af-pt-2 af-flex af-flex-row af-justify-between af-items-center">
        <div>
          <Tag>
            {/* "Inpatient Hospital Claim" is spelled "Inpatient Hopital Claim" in mdh
              raw data, so we correct for that here */}
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              {
                "Outpatient Hospital Claim": "Outpatient Hospital Claim",
                "Inpatient Hopital Claim": "Inpatient Hospital Claim",
              }[batchClaim.claimType]
            }
          </Tag>
          <Tooltip title={`Dates of Service`}>
            <Space>
              <CalendarTwoTone twoToneColor="red" />
              {dateString(
                batchClaim.dateOfServiceStart,
                batchClaim.dateOfServiceEnd,
              )}
            </Space>
          </Tooltip>
        </div>
        <div>
          {batchClaim.claimType === "Inpatient Hopital Claim" &&
            batchClaim.dateAdmit && (
              <Tooltip
                title={`Date Admitted${
                  batchClaim.dateDischarge ? ` - Date Discharged` : ""
                }`}
              >
                <Space>
                  <CalendarTwoTone />
                  {dateString(batchClaim.dateAdmit, batchClaim.dateDischarge)}
                </Space>
              </Tooltip>
            )}
        </div>
      </div>
      <div tw="af-flex af-flex-row">
        <div tw="af-w-1/2">
          {!user.workstation.showOnlyBilledAndAllowedAmount && (
            <>
              <Statistic
                tw="af-flex af-flex-row af-items-baseline"
                title={
                  <div
                    id={"claim-workstation-potential-client-savings"}
                    css={{ marginRight: 29 }}
                  >
                    Potential Savings
                  </div>
                }
                prefix="$"
                value={batchClaim.batchClaimState.potentialClientSavings}
                precision={2}
              />
              <Statistic
                tw="af-flex af-flex-row af-items-baseline"
                title={
                  <div
                    id={"claim-workstation-determined-client-realized-savings"}
                    css={{ marginRight: 29 }}
                  >
                    {batchClaim.batchClaimState.isRealized
                      ? "Realized Savings"
                      : "Determined Savings"}
                  </div>
                }
                prefix="$"
                value={batchClaim.batchClaimState.determinedClientSavings}
                precision={2}
              />
              <Statistic
                tw="af-flex af-flex-row af-items-baseline"
                title={
                  <div
                    id={"claim-workstation-determined-adj-allowed-amount"}
                    css={{ marginRight: 10 }}
                  >
                    Adjusted Allowed Amount
                  </div>
                }
                prefix="$"
                value={
                  batchClaim.batchClaimState.determinedAdjustedAllowedAmount
                }
                precision={2}
              />
            </>
          )}
        </div>
        <div tw="af-w-1/2">
          <Statistic
            tw="af-flex af-flex-row-reverse af-items-baseline"
            title={<div css={{ marginLeft: 26 }}>Billed Amount</div>}
            prefix="$"
            value={batchClaim.amountCharged}
            precision={2}
          />
          <Statistic
            tw="af-flex af-flex-row-reverse af-items-baseline"
            title={<div css={{ marginLeft: 5 }}>Allowed Amount</div>}
            prefix="$"
            value={batchClaim.amountReimbursed}
            precision={2}
          />
        </div>
      </div>
    </div>
  );

  const renderClaimLevelAuditFinding = () => (
    <UBClaimAuditFinding
      {...{
        setCreatingAuditFinding,
        setDecliningAuditFinding,
        ubClaimFindings,
        batchClaim,
        afclFilter,
        readOnly,
        setViewingClaimLevelFinding,
      }}
    />
  );

  const ClaimTab = (userType: any) => {
    return (
      <div>
        <Row>
          <div>
            {
              // claim has files
              files.length > 0 &&
                // user can view docs or doc names
                (user.permissions.viewDocs || user.permissions.viewDocList) &&
                renderViewDocumentsButton()
            }
            {/* Only allow 1 claim level audit finding */}
            {!viewingReport &&
              (user.permissions.findingsActions.includes("create") ||
                user.permissions.findingsActions.includes("decline") ||
                user.permissions.findingsActions.includes("review") ||
                user.permissions.findingsActions.includes("dispute")) &&
              renderClaimAuditFindingButton()}
          </div>
          {user.permissions.findingsActions.includes("view") &&
          ubClaimFindings.nodes.length > 0 &&
          viewingClaimLevelFinding ? (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            <Col span={24} align="middle">
              {renderClaimLevelAuditFinding()}
            </Col>
          ) : (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            <Col span={24} align="middle">
              {renderClaimInfo(userType)}
            </Col>
          )}
        </Row>
      </div>
    );
  };

  const ubClaimFindingAccepted = (ubClaimFindings: any) =>
    ubClaimFindings.nodes.some(({ accepted, reviews }: any) =>
      // if the claim level finding has a review
      // (this is the latest review: see auditFindingReviewsFragment and auditFindingFragment)
      // then return whether or not the latest review is accepted. If there is not a review,
      // then return the accepted value for the original
      reviews.nodes.length > 0
        ? reviews.nodes[0].auditFinding.accepted
        : accepted,
    );

  // The findings button will be disabled if
  // - The claim is not IN_PROGRESS
  // - The user is not a ADMIN or AUDITOR
  // - There exists an accepted UB Claim Finding
  const readOnlyAuditFindings = // -> iife
    workflowClaimState !== "IN_PROGRESS" ||
    user.userType === "PROVIDER" ||
    user.userType === "PAYER" ||
    ubClaimFindingAccepted(ubClaimFindings);

  const tabToolbarRef = React.useRef(null);

  const navigateToIbinRow = useCallback(({ rowId, findingId }: any) => {
    hashBuilder
      .reset()
      .add("IBIN", true)
      .add("rowId", rowId)
      .add("findingId", findingId)
      .commit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs = {
    ...(![
      // n.b. DRG tab mockup is gated to claim 7:
      "80073b1d-3b2a-5237-8ebb-1ab61db66d62", // DEMO claim 7 - claim # dc00007
      "fbcefd4f-3500-5a60-8442-f6ed7fec9c33", // DEMO claim 11 - claim # dc00011
    ].includes(batchClaimId)
      ? {}
      : {
          DRG: (
            <>
              <DrgTab
                batchClaim={batchClaim}
                renderViewDocumentsButton={renderViewDocumentsButton}
                drgReviewData={drgReviewData}
                setDrgReviewData={setDrgReviewData}
              />
            </>
          ),
        }),
    UB: (
      <div tw="af-h-full af-flex af-flex-col">
        <div tw="af-w-full">
          <ClaimTab userType={user.userType} />
        </div>
        <div tw="af-grow">
          <div tw="af-w-full af-h-full">
            <ClaimWorkspaceTable
              {...{
                setCreatingAuditFinding,
                setDecliningAuditFinding,
                navigateToIbinRow,
                readOnly: readOnlyAuditFindings,
                variables: {
                  batchClaimId,
                  afclFilter,
                },
                batchClaim,
              }}
            />
          </div>
        </div>
      </div>
    ),
    IB: (
      <div tw="af-h-full">
        <ItemizedBillTable
          {...{
            setCreatingAuditFinding,
            setDecliningAuditFinding,
            navigateToIbinRow,
            readOnly: readOnlyAuditFindings,
            variables: {
              batchClaimId,
              afclFilter,
            },
          }}
        />
      </div>
    ),
    IBIN: (
      <div tw="af-box-border af-p-0 af-m-0 af-min-h-full af-h-full">
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error */}
        <IbTab
          {...{
            setCreatingAuditFinding,
            setDecliningAuditFinding,
            navigateToIbinRow,
            readOnly: readOnlyAuditFindings,
            variables: {
              batchClaimId,
              batchClaim,
              auditFindings: batchClaim?.auditFindings?.nodes ?? [],
              afclFilter,
            },
          }}
          tabToolbarRef={tabToolbarRef}
        />
      </div>
    ),
    Findings: (
      <FindingsTable
        {...{
          setCreatingAuditFinding,
          setDecliningAuditFinding,
          navigateToIbinRow,
          readOnly: readOnlyAuditFindings,
          variables: {
            batchClaimId,
            afclFilter,
          },
        }}
      />
    ),
    Reports: (
      <ReportsVersionList batchClaimId={batchClaimId} isLatestVersion={false} />
    ),
  };

  const visibleTabs = useMemo(() => user.workstation.tabs, [user]);

  // Extracts selectedTabName from URL
  // If no valid tab selection is in the URL then it is set to 'UB' by default
  const tabKeys = Object.keys(tabs);
  const [hashParams, hashBuilder] = useHashParams([tabKeys]);
  const selectedTab = Object.keys(hashParams).find((hp) =>
    tabKeys.includes(hp),
  ); // history.location.hash.replace('#', '').split('&')[0];
  const setSelectedTab = (tab: any) => {
    // history.replace(`${history.location.pathname}#${tab}`);
    hashBuilder.reset().add(tab, true).commit();
    ctxRefresh();
  };
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (!(selectedTab in tabs) || !(selectedTab in visibleTabs)) {
      // set default selected tab to 'UB'
      setSelectedTab("UB");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const filteredTabs = useMemo(() => {
    const ft = Object.keys(tabs)
      .filter((key) => {
        return key in visibleTabs;
      })
      .map((key) => ({
        key,
        label: key,
        children: undefined,
      }));
    return ft;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleTabs]); // tabs is const so no need in deps

  return (
    <SplitViewProvider>
      <SplitView />
      <div tw="af-h-full af-max-h-full af-min-h-full af-scrollbar-none af-flex af-flex-col af-px-2">
        <div>
          <ClaimWorkspaceHeader
            batchClaim={batchClaim}
            variables={{ afclFilter }}
          />
        </div>
        <div tw="af-flex af-flex-row">
          <Tabs
            activeKey={selectedTab}
            items={filteredTabs}
            size="small"
            onChange={setSelectedTab}
            className="content_row_card"
            style={{ width: "100%" }}
            destroyInactiveTabPane={false}
            tabBarExtraContent={{
              right: (
                <div tw="af-max-w-lg af-h-8 af-p-2 af-max-h-8 af-overflow-hidden">
                  <div ref={tabToolbarRef}></div>
                </div>
              ),
            }}
          ></Tabs>
        </div>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error */}
        <div tw="af-grow">{tabs[selectedTab]}</div>
        <ViewDocumententationModal
          viewDocumentNamesModalVisible={viewDocumentNamesModalVisible}
          setViewDocumentNamesModalVisible={setViewDocumentNamesModalVisible}
          batchClaimId={batchClaimId}
        />
        {user.permissions.findingsActions.includes("create") && (
          <CreateAuditFindingModal
            {...{
              ...creatingAuditFinding,
              setCreatingAuditFinding,
              batchClaim,
              afclFilter,
            }}
          />
        )}
        <DeclineAuditFindingModal
          {...{
            ...decliningAuditFinding,
            setDecliningAuditFinding,
            afclFilter,
          }}
        />
      </div>
    </SplitViewProvider>
  );
};

const ClaimWorkspace = PureClaimWorkspace;

export { ClaimWorkspace, PureClaimWorkspace };
