import { useShallow } from "zustand/react/shallow";

import type { RotateDirection, ZoomStep } from "./helper-types";
import type { CombinedPDFStore } from "./providers/pdf-store-provider";
import { usePDFStore } from "./providers/pdf-store-provider";

export function usePDF() {
  const state = usePDFStore((s) => s.state);
  const selector = useShallow((s: CombinedPDFStore) => ({
    pdfUrl: s.pdfUrl,
    currentPageIndex: s.currentPageIndex,
    pageCount: s.pageCount,
    readOnlyScale: s.scale,
    error: s.error,
    bookmarks: s.bookmarks,
    open: s.open,
    close: s.close,
    nextPage: s.nextPage,
    prevPage: s.prevPage,
    jumpToPage: s.jumpToPage,
    zoom: s.zoom,
    rotate: s.rotate,
    highlightTextOnPage: s.highlightTextOnPage,
    removeHighlightsOnPage: s.removeHighlightsOnPage,
    getPageThumbnail: s.getPageThumbnail,
    loadBookmarks: s.loadBookmarks,
    addEventListener: s.addEventListener,
    removeEventListener: s.removeEventListener,
    events: s.events,
  }));
  const { data: controller } = usePDFStore(selector);

  const open = (url: string, page?: number, highlights?: string[]) => {
    if (url !== controller?.pdfUrl || page !== controller?.currentPageIndex) {
      return controller?.open(url, page, undefined, highlights);
    }
  };

  const close = () => controller?.close();

  const nextPage = () => controller?.nextPage();

  const prevPage = () => controller?.prevPage();

  const jumpToPage = (pageIndex: number) => controller?.jumpToPage(pageIndex);

  const zoom = (step: ZoomStep) => controller?.zoom(step);

  const rotate = (direction: RotateDirection) => controller?.rotate(direction);

  const highlightTextOnPage = (
    pageIndex: number,
    stringsToHighlight: string[],
  ) => controller?.highlightTextOnPage(pageIndex, stringsToHighlight);

  const removeHighlightsOnPage = (pageIndex: number) =>
    controller?.removeHighlightsOnPage(pageIndex);

  const getPageThumbnail = (pageIndex: number) =>
    controller?.getPageThumbnail(pageIndex) ?? Promise.resolve(null);

  const loadBookmarks = () => controller?.loadBookmarks();

  return {
    currentPage: controller?.currentPageIndex ?? 0,
    pagesCount: controller?.pageCount ?? 0,
    readOnlyScale: controller?.readOnlyScale ?? "fitWidth",
    state,
    error: controller?.error,
    bookmarks: controller?.bookmarks ?? null,
    open,
    close,
    nextPage,
    prevPage,
    jumpToPage,
    rotate,
    zoom,
    highlightTextOnPage,
    removeHighlightsOnPage,
    getPageThumbnail,
    loadBookmarks,
    addEventListener: controller?.addEventListener,
    removeEventListener: controller?.removeEventListener,
    events: controller?.events,
  };
}
