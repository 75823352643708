import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { IconLoader2 } from "@tabler/icons-react";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";

import { cn } from "@alaffia-technology-solutions/tailwind-utils";

const buttonVariants = cva(
  "af-inline-flex af-items-center af-justify-center af-rounded-md af-text-sm af-leading-6 af-font-semibold af-ring-offset-background af-transition-colors focus-visible:af-outline-none focus-visible:af-ring-2 focus-visible:af-ring-ring focus-visible:af-ring-offset-2 disabled:af-pointer-events-none disabled:af-opacity-50 af-border-0",
  {
    variants: {
      variant: {
        default:
          "af-bg-primary af-text-primary-foreground hover:af-bg-primary/90",
        destructive:
          "af-bg-destructive af-text-destructive-foreground hover:af-bg-destructive/90",
        outline:
          "af-text-primary-500 af-border af-border-current af-bg-transparent hover:af-text-accent-foreground hover:af-bg-accent",
        secondary:
          "af-bg-secondary-500 af-text-secondary-foreground hover:af-bg-secondary-600 disabled:af-opacity-100 disabled:af-bg-gray-300 disabled:af-text-gray-700",
        ghost: "hover:af-bg-accent hover:af-text-accent-foreground",
        link: "af-text-primary af-underline-offset-4 hover:af-underline af-decoration-current",
        success: "af-bg-success-500 af-text-white hover:af-bg-success-600",
        successOutline:
          "af-text-success-500 af-border af-border-current af-bg-background hover:af-text-success-600",
        neutral: "af-bg-gray-300 af-text-gray-700 hover:af-bg-gray-400",
        neutralGhost:
          "af-text-gray-600 hover:af-bg-gray-200 hover:af-text-gray-700",
        neutralOutline:
          "af-text-gray-600 af-border af-border-gray-600 af-bg-transparent hover:af-text-gray-700 hover:af-border-gray-700",
      },
      size: {
        default: "af-h-10 af-px-6 af-py-2",
        sm: "af-h-8 af-px-4 af-py-1",
        lg: "af-h-11 af-px-8 af-py-3",
        icon: "af-h-8 af-w-8",
        inline: "af-h-auto af-px-0 af-py-0",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, asChild = false, loading, children, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    const type = asChild ? undefined : "button";

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        type={type}
        disabled={loading}
        {...props}
      >
        {loading ? <IconLoader2 className="af-animate-spin" /> : children}
      </Comp>
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
