import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import type { ImperativePanelHandle } from "react-resizable-panels";

import { useResizeObserver } from "@alaffia-technology-solutions/hooks";

const EXPANDED_FILES_SIDEBAR_THRESHOLD = 688;
const MIN_SIZE_IN_PIXELS = 67;

interface UseExpandablePanelOptions {
  /**
   * The ref of the wrapper element.
   * @type {React.RefObject<Element>}
   */
  wrapperRef: React.RefObject<Element>;
  /**
   * The threshold at which the files sidebar should be expanded.
   * @type {number}
   */
  expandedFilesSidebarThreshold?: number;
  /**
   * The minimum size of the panel in pixels.
   * @type {number}
   */
  minSizeInPixels?: number;
  /**
   * The id of the panel group.
   * @type {string}
   */
  resizeHandleSelector: string;
  /**
   * Whether the panel should be expanded by default.
   * @type {boolean}
   */
  defaultExpanded?: boolean;
}

/**
 * A hook that provides functionality to expand and collapse a panel.
 * @param {UseExpandablePanelOptions} options - The options to configure the hook.
 * @returns {Object} An object containing the expand and collapse functions, the panel's state, and the panel's ref.
 * @property {Function} expandPanel - A function to expand the panel.
 * @property {Function} collapsePanel - A function to collapse the panel.
 * @property {boolean} isExpanded - A boolean indicating whether the panel is expanded.
 * @property {React.RefObject<ImperativePanelHandle>} ref - The ref of the panel.
 * @property {number} minSize - The minimum size of the panel.
 */
export const useExpandablePanel = ({
  wrapperRef,
  expandedFilesSidebarThreshold = EXPANDED_FILES_SIDEBAR_THRESHOLD,
  minSizeInPixels = MIN_SIZE_IN_PIXELS,
  resizeHandleSelector,
  defaultExpanded,
}: UseExpandablePanelOptions) => {
  const { width: wrapperWidth } = useResizeObserver({ ref: wrapperRef });

  const ref = useRef<ImperativePanelHandle>(null);
  const isExpandedTouched = useRef(false);
  const [isExpanded, setIsExpanded] = useState(
    defaultExpanded ?? wrapperWidth >= expandedFilesSidebarThreshold,
  );
  const setIsExpandedState = useCallback((value: boolean) => {
    setIsExpanded(value);
    isExpandedTouched.current = true;
  }, []);

  const collapsePanel = useCallback(() => {
    setIsExpandedState(false);
    ref.current?.collapse();
  }, [setIsExpandedState]);

  const expandPanel = useCallback(() => {
    setIsExpandedState(true);
    ref.current?.expand();
  }, [setIsExpandedState]);

  useEffect(() => {
    if (!isExpandedTouched.current) {
      const shouldExpand =
        defaultExpanded ?? wrapperWidth >= expandedFilesSidebarThreshold;
      setIsExpanded(shouldExpand);
      shouldExpand ? expandPanel() : collapsePanel();
    }
  }, [
    collapsePanel,
    defaultExpanded,
    expandPanel,
    expandedFilesSidebarThreshold,
    setIsExpanded,
    wrapperWidth,
  ]);

  const [minSize, setMinSize] = useState(10);

  useLayoutEffect(() => {
    const resizeHandles =
      document.querySelectorAll<HTMLDivElement>(resizeHandleSelector);

    let width = wrapperWidth;

    resizeHandles.forEach((resizeHandle) => {
      width -= resizeHandle.offsetWidth;
    });

    setMinSize((minSizeInPixels / width) * 100);
  }, [wrapperWidth, minSizeInPixels, resizeHandleSelector]);

  return {
    expandPanel,
    collapsePanel,
    isExpanded,
    ref,
    minSize,
  };
};
