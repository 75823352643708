import { useCallback, useEffect, useRef } from "react";

import { usePDF } from "@alaffia-technology-solutions/pdf-viewer";

interface UsePDFViewerProps {
  highlights: string[] | undefined;
  openDelay: number;
  page?: number;
  url: string;
  onSearch?: (query: string) => void | Promise<void>;
  searchQuery: string;
}

export const usePDFViewer = ({
  highlights,
  openDelay,
  page,
  url,
  onSearch: extOnSearch,
  searchQuery,
}: UsePDFViewerProps) => {
  const {
    currentPage,
    state,
    pagesCount,
    readOnlyScale,
    error,
    close,
    jumpToPage,
    open,
    nextPage,
    zoom,
    prevPage,
    rotate,
    getPageThumbnail,
    highlightTextOnPage,
    removeHighlightsOnPage,
    bookmarks,
  } = usePDF();

  const isDisabled = !!(state.isServer || state.data === "initial" || error);
  const urlRef = useRef<string>();
  const pageRef = useRef<number>();
  const highlightsRef = useRef(highlights);

  useEffect(() => {
    highlightsRef.current = highlights;
  }, [highlights]);

  useEffect(() => {
    if (url !== urlRef.current) {
      // Reset state to avoid long requests stacking
      void close();
      urlRef.current = url;
      pageRef.current = page;
      setTimeout(() => {
        open(url, page, highlightsRef.current)?.catch((error) =>
          console.error(error),
        );
      }, openDelay);
      return;
    }

    if (page !== pageRef.current && page) {
      urlRef.current = url;
      pageRef.current = page;
      jumpToPage(page - 1)?.catch((error) => console.error(error));
    }
  }, [url, open, openDelay, error, page, close, jumpToPage]);

  useEffect(() => {
    return () => {
      void close(); // reset store state on unmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = useCallback(
    async (query: string) => {
      await extOnSearch?.(query);
      await removeHighlightsOnPage(currentPage);
      await highlightTextOnPage(currentPage, [query]);
    },
    [currentPage, extOnSearch, highlightTextOnPage, removeHighlightsOnPage],
  );

  useEffect(() => {
    if (searchQuery) {
      onSearch(searchQuery).catch((error) => console.error(error));
    }
  }, [onSearch, searchQuery]);

  return {
    currentPage,
    isDisabled,
    getPageThumbnail,
    jumpToPage,
    nextPage,
    pagesCount,
    prevPage,
    readOnlyScale,
    rotate,
    zoom,
    highlightTextOnPage,
    removeHighlightsOnPage,
    onSearch,
    bookmarks,
  };
};
