/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */

export interface MfaCodeArgs {
  code: string;
}

export interface ChangePwdArgs {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export type ChallengeArgs = MfaCodeArgs | ChangePwdArgs;

export const isMfaCodeArgs = (args: ChallengeArgs): args is MfaCodeArgs =>
  (args as any)?.code;

export const isChangePwdArgs = (args: ChallengeArgs): args is ChangePwdArgs =>
  (args as any)?.oldPassword &&
  (args as any)?.newPassword &&
  (args as any)?.confirmPassword;
