import { useCallback, useEffect, useMemo, useState } from "react";
import type {
  FetchQueryOptions,
  MutationKey,
  QueryKey,
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
} from "@tanstack/react-query";
import {
  useInfiniteQuery,
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import type { AlaffiaClient } from "@alaffia-technology-solutions/client-sdk";
import { useEventCallback } from "@alaffia-technology-solutions/hooks";

import { useAlaffiaClientContext } from "../AlaffiaClient.context";

interface AlaffiaClientResponse<TData, TError> {
  data: TData | null;
  error: TError | null;
}

type AlaffiaClientBasicMethodSelector<
  TInput extends Record<string, unknown>,
  TData,
> = (
  alaffiaClient: AlaffiaClient,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => (input: TInput, ...internalInput: any[]) => TData | Promise<TData>;

export type AlaffiaClientMethodSelector<
  TInput extends Record<string, unknown>,
  TData,
  TError,
> = AlaffiaClientBasicMethodSelector<
  TInput,
  AlaffiaClientResponse<TData, TError>
>;

interface Observable<T> {
  subscribe: (handler: (data: T) => void) => { unsubscribe: () => void };
}

type AlaffiaClientObservableSelector<TData> = (
  alaffiaClient: AlaffiaClient,
) => Observable<TData>;

export type InfiniteQueryHookOptions<
  TData,
  TError,
  TQueryKey extends QueryKey,
> = Omit<
  UseInfiniteQueryOptions<TData, TError, TData, TData, TQueryKey>,
  "queryKey" | "queryFn"
>;

export type QueryHookOptions<TData, TError, TQueryKey extends QueryKey> = Omit<
  UseQueryOptions<TData, TError, TData, TQueryKey>,
  "queryKey" | "queryFn"
>;

export type MutationHookOptions<TData, TError, TVariables, TContext> = Omit<
  UseMutationOptions<TData, TError, TVariables, TContext>,
  "mutationFn"
>;

export type QueryKeyFn<
  TInput extends Record<string, unknown>,
  TQueryKey extends QueryKey,
> = (input: TInput) => TQueryKey;

export interface CreateQueryHookOptions<
  TInput extends Record<string, unknown>,
  TData,
  TError,
  TQueryKey extends QueryKey,
> {
  methodSelector: AlaffiaClientMethodSelector<TInput, TData, TError>;
  queryKeyFn: QueryKeyFn<TInput, TQueryKey>;
}

export const createQueryHook = <
  TInput extends Record<string, unknown> = Record<string, unknown>,
  TData = unknown,
  TError = unknown,
  TQueryKey extends QueryKey = QueryKey,
>({
  methodSelector,
  queryKeyFn,
}: CreateQueryHookOptions<TInput, TData, TError, TQueryKey>) => {
  const createQueryKey = <TPageParam, TInternalInput extends unknown[]>(
    input: TInput,
    internalInputFn?: (pageParam: TPageParam | null) => TInternalInput,
  ) => {
    const queryKey = queryKeyFn({
      ...input,
      ...(internalInputFn ? internalInputFn(null) : {}),
      infinite: true,
    });

    return queryKey;
  };

  const createQueryFn =
    <TPageParam, TInternalInput extends unknown[]>(
      alaffiaClient: AlaffiaClient,
      input: TInput,
      pageParam?: TPageParam | null,
      internalInputFn?: (pageParam: TPageParam | null) => TInternalInput,
    ) =>
    async () => {
      const queryMethod = methodSelector(alaffiaClient);

      let response: AlaffiaClientResponse<TData, TError>;

      if (internalInputFn) {
        response = await queryMethod(
          input,
          ...internalInputFn(pageParam ?? null),
        );
      } else {
        response = await queryMethod(input);
      }

      if (response.error) {
        throw response.error;
      }

      return response.data;
    };

  return {
    useInfiniteQuery: <TPageParam, TInternalInput extends unknown[]>(
      input: TInput,
      options: InfiniteQueryHookOptions<TData, TError, TQueryKey> = {},
      paginationParams?: {
        initialPageParam: TPageParam | null;
        internalInputFn: (pageParam: TPageParam | null) => TInternalInput;
      },
    ) => {
      const { alaffiaClient } = useAlaffiaClientContext();

      return useInfiniteQuery({
        queryKey: createQueryKey(input, paginationParams?.internalInputFn),
        queryFn: ({ pageParam }: { pageParam: TPageParam | null }) =>
          createQueryFn(
            alaffiaClient,
            input,
            pageParam ?? paginationParams?.initialPageParam,
            paginationParams?.internalInputFn,
          )(),
        ...options,
      } as UseInfiniteQueryOptions<TData, TError, TData, TData, TQueryKey>);
    },
    useQuery: (
      input: TInput,
      options: QueryHookOptions<TData, TError, TQueryKey> = {},
    ) => {
      const { alaffiaClient } = useAlaffiaClientContext();
      return useQuery({
        queryKey: queryKeyFn(input),
        queryFn: createQueryFn(alaffiaClient, input),
        ...options,
      } as UseQueryOptions<TData, TError, TData, TQueryKey>);
    },
    useQueryContext: () => {
      const queryClient = useQueryClient();
      const { alaffiaClient } = useAlaffiaClientContext();

      const invalidate = useCallback(
        (input: TInput) =>
          queryClient.invalidateQueries({ queryKey: queryKeyFn(input) }),
        [queryClient],
      );
      const cancel = useCallback(
        (input: TInput) =>
          queryClient.cancelQueries({ queryKey: queryKeyFn(input) }),
        [queryClient],
      );
      const getData = useCallback(
        (input: TInput) => queryClient.getQueryData<TData>(queryKeyFn(input)),
        [queryClient],
      );
      const setData = useCallback(
        (input: TInput, data: TData | undefined) =>
          queryClient.setQueryData(queryKeyFn(input), data),
        [queryClient],
      );
      const fetch = useCallback(
        (
          input: TInput,
          options?: Omit<
            FetchQueryOptions<TData, TError, TData, TQueryKey>,
            "queryKey" | "queryFn"
          >,
        ) =>
          queryClient.fetchQuery({
            queryKey: queryKeyFn(input),
            queryFn: createQueryFn(alaffiaClient, input),
            ...options,
          } as FetchQueryOptions<TData, TError, TData, TQueryKey>),
        [queryClient, alaffiaClient],
      );

      return useMemo(
        () => ({
          invalidate,
          cancel,
          getData,
          setData,
          fetch,
        }),
        [cancel, fetch, getData, invalidate, setData],
      );
    },
  };
};

export interface CreateMutationHookOptions<
  TData,
  TError,
  TVariables extends Record<string, unknown>,
> {
  mutationKey: MutationKey;
  methodSelector: AlaffiaClientMethodSelector<TVariables, TData, TError>;
}

export const createMutationHook = <
  TData = unknown,
  TError = unknown,
  TVariables extends Record<string, unknown> = Record<string, unknown>,
>({
  mutationKey,
  methodSelector,
}: CreateMutationHookOptions<TData, TError, TVariables>) => {
  return createBasicMutationHook({
    mutationKey,
    methodSelector,
    transformResult: (result) => {
      if (result.error) {
        throw result.error;
      }

      return result.data;
    },
  });
};

export interface CreateBasicMutationHookOptions<
  TResult,
  TData,
  TVariables extends Record<string, unknown>,
> {
  mutationKey: MutationKey;
  methodSelector: AlaffiaClientBasicMethodSelector<TVariables, TResult>;
  transformResult?: (result: TResult) => TData;
}

export const createBasicMutationHook = <
  TResult = unknown,
  TData = TResult,
  TError = unknown,
  TVariables extends Record<string, unknown> = Record<string, unknown>,
>({
  mutationKey,
  methodSelector,
  transformResult,
}: CreateBasicMutationHookOptions<TResult, TData, TVariables>) => ({
  useMutation: <TContext>(
    options: MutationHookOptions<TData, TError, TVariables, TContext> = {},
  ) => {
    const { alaffiaClient } = useAlaffiaClientContext();
    return useMutation({
      mutationKey,
      mutationFn: async (variables) => {
        const mutationMethod = methodSelector(alaffiaClient);

        const result = await mutationMethod(variables);
        if (transformResult) {
          return transformResult(result);
        }
        return result;
      },
      ...options,
    } as UseMutationOptions<TData, TError, TVariables, TContext>);
  },
  useIsMutating: () => useIsMutating({ mutationKey }),
});

export interface CreateSubscriptionHookOptions<TData> {
  observableSelector: AlaffiaClientObservableSelector<TData>;
}

export const createSubscriptionHook = <TData>({
  observableSelector,
}: CreateSubscriptionHookOptions<TData>) => ({
  useSubscription: (handler: (data: TData) => void) => {
    const { alaffiaClient } = useAlaffiaClientContext();
    const handlerCallback = useEventCallback(handler);

    useEffect(() => {
      const subscription =
        observableSelector(alaffiaClient).subscribe(handlerCallback);
      return () => subscription.unsubscribe();
    }, [alaffiaClient, handlerCallback]);
  },
});

export interface CreateSubscriptionDataHookOptions<TResult, TData> {
  observableSelector: AlaffiaClientObservableSelector<TResult>;
  transformResult: (result: TResult) => TData;
}

export const createSubscriptionDataHook = <TResult, TData = TResult>({
  observableSelector,
  transformResult,
}: CreateSubscriptionDataHookOptions<TResult, TData>) => {
  const { useSubscription } = createSubscriptionHook({ observableSelector });
  return {
    useSubscription: () => {
      const [data, setData] = useState<TData | null>(null);
      useSubscription((result) => setData(transformResult(result)));

      return data;
    },
  };
};
