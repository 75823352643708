/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Menu, message } from "antd";
import { gql, useMutation } from "urql";

import { batchClaimState } from "../../../fragments";
import { batchClaimsNodeKey, claimMenuItemDetails } from "../util";

const setProviderSignOffMutation = gql`
  mutation setProviderSignOffMutation(
    $batchClaimIds: [UUID!]!
    $state: ProviderSignOffClaimStateEnum!
  ) {
    setProviderSignOffClaimStates(
      input: { batchClaimIds: $batchClaimIds, providerSignOffState: $state }
    ) {
      providerSignOffClaimStates {
        batchClaim {
          ...batchClaimState
        }
      }
    }
  }
  ${batchClaimState}
`;

const setClientSignOffMutation = gql`
  mutation setClientSignOffMutation(
    $batchClaimIds: [UUID!]!
    $state: ClientSignOffClaimStateEnum!
  ) {
    setClientSignOffClaimStates(
      input: { batchClaimIds: $batchClaimIds, clientSignOffState: $state }
    ) {
      clientSignOffClaimStates {
        batchClaim {
          ...batchClaimState
        }
      }
    }
  }
  ${batchClaimState}
`;

const actionSubMenuItem = ({
  batchClaims,
  setSignOff,
  action,
  value,
  actionCriteria,
  title,
  setLoading,
}: any) => {
  const actionClaims = batchClaims.filter(
    (i: any) => actionCriteria(i).satisfied,
  );
  return (
    <Menu.Item
      key={`${action}-${value}-menuitem-${batchClaimsNodeKey(batchClaims)}`}
      disabled={!actionClaims.length}
      onClick={async () => {
        setLoading(true);
        const { data, error } = await setSignOff({
          batchClaimIds: actionClaims.map(({ id }: any) => id),
          state: value,
        });

        setLoading(false);
        if (!data && error) {
          const errorMessage = `${title} failed`;
          console.error(`${errorMessage}: ${error.message}`);
          void message.error(errorMessage);
        } else {
          void message.success(`${title} success`);
        }
      }}
    >
      {title} {claimMenuItemDetails({ batchClaims, actionClaims })}
    </Menu.Item>
  );
};

// sign off actions
// two types of sign offs ( client, provider )
// each type has two possible values - sign off or no sign off
const typeActions: any = {
  setProviderSignOff: {
    mutation: setProviderSignOffMutation,
    values: [
      {
        title: "Add Provider Sign Off",
        value: "PROVIDER_SIGN_OFF",
        actionCriteria: ({
          batchClaimState: {
            reported,
            workflowClaimState,
            invoiceClaimState,
            negotiationClaimState,
            providerSignOffClaimState,
          },
        }: any) => {
          const satisfied =
            // must be reported
            reported &&
            // claim cant be signed off on while in progress
            ["COMPLETED", "REPORTED_TO_CLIENT", "REPORTED_TO_PROVIDER"].includes(workflowClaimState) &&
            // claim cannot be paid
            invoiceClaimState !== "PAID" &&
            // claim cannot be in negotiation
            negotiationClaimState !== "NEGOTIATING" &&
            // claim is not already signed off
            providerSignOffClaimState !== "PROVIDER_SIGN_OFF";

          return { satisfied };
        },
      },
      {
        title: "Remove Provider Sign Off",
        value: "PROVIDER_NO_SIGN_OFF",
        actionCriteria: ({
          batchClaimState: {
            invoiceClaimState,
            negotiationClaimState,
            providerSignOffClaimState,
          },
        }: any) => {
          const satisfied =
            // claim cannot be paid
            invoiceClaimState !== "PAID" &&
            // claim cannot be in negotiation
            negotiationClaimState !== "NEGOTIATING" &&
            // claim has provider sign off
            providerSignOffClaimState === "PROVIDER_SIGN_OFF";

          return { satisfied };
        },
      },
    ],
    renderMenuItem: ({ batchClaims, mutation, action, setLoading }: any) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [, setSignOff] = useMutation(mutation);

      return typeActions[action].values.map(
        ({ title, value, actionCriteria }: any) =>
          actionSubMenuItem({
            batchClaims,
            setSignOff,
            action,
            actionCriteria,
            title,
            value,
            setLoading,
          }),
      );
    },
  },
  setClientSignOff: {
    mutation: setClientSignOffMutation,
    values: [
      {
        title: "Add Client Sign Off",
        value: "CLIENT_SIGN_OFF",
        actionCriteria: ({
          batchClaimState: {
            reported,
            workflowClaimState,
            invoiceClaimState,
            negotiationClaimState,
            clientSignOffClaimState,
          },
        }: any) => {
          const satisfied =
            // must be reported
            reported &&
            // claim cant be signed off on while in progress
            ["COMPLETED", "REPORTED_TO_CLIENT", "REPORTED_TO_PROVIDER"].includes(workflowClaimState) &&
            // claim cannot be paid
            invoiceClaimState !== "PAID" &&
            // claim cannot be in negotiation
            negotiationClaimState !== "NEGOTIATING" &&
            // claim is not already signed off
            clientSignOffClaimState !== "CLIENT_SIGN_OFF";

          return { satisfied };
        },
      },
      {
        title: "Remove Client Sign Off",
        value: "CLIENT_NO_SIGN_OFF",
        actionCriteria: ({
          batchClaimState: {
            invoiceClaimState,
            negotiationClaimState,
            clientSignOffClaimState,
          },
        }: any) => {
          const satisfied =
            // claim cannot be paid
            invoiceClaimState !== "PAID" &&
            // claim cannot be in negotiation
            negotiationClaimState !== "NEGOTIATING" &&
            // claim has client sign off
            clientSignOffClaimState === "CLIENT_SIGN_OFF";

          return { satisfied };
        },
      },
    ],
    renderMenuItem: ({ batchClaims, mutation, action, setLoading }: any) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [, setSignOff] = useMutation(mutation);

      return typeActions[action].values.map(
        ({ title, value, actionCriteria }: any) =>
          actionSubMenuItem({
            batchClaims,
            setSignOff,
            action,
            actionCriteria,
            title,
            value,
            setLoading,
          }),
      );
    },
  },
};

const actionSubMenu = ({ batchClaims, permissions, setLoading }: any) => (
  <Menu.SubMenu
    key={`sign-off-submenu-${batchClaimsNodeKey(batchClaims)}`}
    title="Sign Offs"
  >
    {Object.entries(typeActions)
      .filter(([action, _]) =>
        permissions.claimActions.signOff.includes(action),
      )
      .map(([action, { renderMenuItem, ...actionProps }]: any) =>
        renderMenuItem({ batchClaims, action, setLoading, ...actionProps }),
      )}
  </Menu.SubMenu>
);

// The subMenu property must return a function and not a react component
// Or else unexpected behavior can occur such as a non-unique key error from antd
// https://ant.design/components/menu/#Notes-for-developers
const signOff = {
  title: "Sign Offs",
  subMenu: actionSubMenu,
};

export { signOff };
