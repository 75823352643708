import { useState } from "react";
import {
  BookOutlined,
  FileOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";

import { apiClient } from "@alaffia-technology-solutions/client-sdk-react-query";
import { SearchTooltip } from "@alaffia-technology-solutions/components";
import type { PDFSidebarTab } from "@alaffia-technology-solutions/pdf-viewer";
import {
  PDFBookmarks,
  PDFSidebar,
  PDFThumbnails,
  PDFToolbar,
  PDFViewerCanvas,
  SearchResults,
  usePDFSidebarTabs,
} from "@alaffia-technology-solutions/pdf-viewer";
import type { File } from "@alaffia-technology-solutions/schema";

import { usePDFViewer } from "./usePdfViewer";

interface PdfViewerProps {
  fileId: File["id"];
  url: string;
  openDelay?: number;
  page?: number;
  highlights?: string[];
  searchEnabled?: boolean;
}

export const PdfViewer = ({
  fileId,
  url,
  page,
  openDelay = 0,
  highlights,
  searchEnabled,
}: PdfViewerProps) => {
  const [query, setQuery] = useState("");
  const { data: user } = apiClient.auth.getUser.useQuery({});

  const { data } = apiClient.files.searchInFile.useQuery(
    {
      fileId,
      query,
    },
    {
      enabled: Boolean(query),
    },
  );

  const onResultPick = async (id?: string) => {
    const pageNumber = data?.find((element) => element.id === id)?.pageNumber;

    if (!pageNumber) {
      return;
    }

    const pageIndex = pageNumber - 1;

    await jumpToPage(pageIndex);
  };

  const {
    currentPage,
    getPageThumbnail,
    jumpToPage,
    isDisabled,
    nextPage,
    pagesCount,
    prevPage,
    readOnlyScale,
    rotate,
    zoom,
    bookmarks,
    onSearch,
  } = usePDFViewer({
    highlights,
    openDelay,
    page,
    url,
    searchQuery: query,
    onSearch: setQuery,
  });

  const sidebarTabs = [
    {
      key: "thumbnails",
      icon: <FileOutlined className="af-size-4" />,
      title: "Thumbnails",
      content: (
        <PDFThumbnails
          jumpToPage={jumpToPage}
          fileId={fileId}
          pagesCount={pagesCount}
          currentPage={currentPage}
          getPageThumbnail={getPageThumbnail}
        />
      ),
      triggerProps: {
        disabled: isDisabled,
      },
    },
    {
      key: "search-results",
      icon: <FileSearchOutlined className="af-size-4" />,
      title: "Search Results",
      content: (
        <SearchResults
          onResultPick={onResultPick}
          query={query}
          results={data ?? []}
        />
      ),
      triggerProps: {
        disabled: isDisabled,
      },
    },
    {
      key: "bookmarks",
      icon: <BookOutlined className="af-size-4" />,
      title: "Bookmarks",
      content: (
        <PDFBookmarks
          bookmarks={bookmarks ?? []}
          onBookmarkClick={(bookmark) => jumpToPage(bookmark.page)}
        />
      ),
      triggerProps: {
        disabled: isDisabled || !bookmarks || bookmarks.length === 0,
      },
    },
  ] as const satisfies PDFSidebarTab[];

  const [selectedTab, setSelectedTab] = usePDFSidebarTabs(sidebarTabs);

  const handleTabChange = (tab: (typeof sidebarTabs)[number]["key"] | null) => {
    setSelectedTab(tab);
    return zoom("fitWidth");
  };

  const handleSearch = async (query: string) => {
    await onSearch(query);
    return handleTabChange("search-results");
  };

  const searchTooltipMessage =
    user?.userType === "PAYER" || user?.userType === "PROVIDER"
      ? "Ask your Account Manager about 'Ask Autodor,' Alaffia's AI enabled assistant for claim review."
      : "Search is unavailable for original files.";

  return (
    <div
      className="af-pdf-viewer af-relative af-z-10 af-h-full af-w-full af-overflow-hidden af-p-2"
      key={url}
    >
      <div className="af-relative af-flex af-h-full af-flex-col af-overflow-hidden af-rounded-md af-border af-border-border af-bg-white">
        <PDFToolbar
          disabled={isDisabled}
          currentPage={currentPage}
          onJumpToPage={jumpToPage}
          onNextPage={nextPage}
          onPrevPage={prevPage}
          pagesCount={pagesCount}
          zoomScale={readOnlyScale}
          onRotate={rotate}
          onZoom={zoom}
          onSearch={handleSearch}
          url={url}
          searchEnabled={searchEnabled}
          searchTooltip={<SearchTooltip message={searchTooltipMessage} />}
        />
        <div className="af-m-1 af-flex af-h-full af-overflow-hidden [&_ul]:af-pl-3">
          <PDFSidebar
            tabs={sidebarTabs}
            selectedTab={selectedTab}
            onTabChange={handleTabChange}
          />
          <PDFViewerCanvas />
        </div>
      </div>
    </div>
  );
};
