{
  "name": "@alaffia-technology-solutions/alaffia",
  "private": true,
  "version": "0.1.1-next.3",
  "license": "MIT",
  "repository": {
    "type": "git"
  },
  "source": "src/index.html",
  "scripts": {
    "build": "pnpm with-env -f .env.development pnpm build:_",
    "build:_": "parcel build --no-scope-hoist",
    "clean": "git clean -xdf .parcel-cache .turbo dist node_modules",
    "codegen": "graphql-codegen && node ./introspection-gen.js",
    "format": "prettier --check . --ignore-path ../../.gitignore --ignore-path ./.prettierignore",
    "lint": "eslint",
    "start": "pnpm with-env -f .env.development parcel -p 3000 --open --watch-dir ../../",
    "test": "TZ=utc jest",
    "typecheck": "tsc --noEmit",
    "with-env": "env-cmd",
    "serve-dist-build": "node scripts/express-serve-dist.js"
  },
  "dependencies": {
    "@alaffia-technology-solutions/auth": "workspace:*",
    "@alaffia-technology-solutions/client-sdk": "workspace:*",
    "@alaffia-technology-solutions/client-sdk-react-query": "workspace:*",
    "@alaffia-technology-solutions/components": "workspace:*",
    "@alaffia-technology-solutions/hooks": "workspace:*",
    "@alaffia-technology-solutions/pdf-viewer": "workspace:*",
    "@alaffia-technology-solutions/react-utils": "workspace:*",
    "@alaffia-technology-solutions/schema": "workspace:*",
    "@alaffia-technology-solutions/tailwind-utils": "workspace:*",
    "@alaffia-technology-solutions/theme": "workspace:*",
    "@alaffia-technology-solutions/ui": "workspace:*",
    "@ant-design/compatible": "^5.1.1",
    "@ant-design/icons": "5.3.2",
    "@datadog/browser-rum": "^4.32.0",
    "@emotion/react": "^11.11.3",
    "@emotion/styled": "^11.11.0",
    "@foxitsoftware/foxit-pdf-sdk-for-web-library-full": "^9.2.1",
    "@graphql-typed-document-node/core": "^3.2.0",
    "@headlessui/react": "^1.4.1",
    "@heroicons/react": "^2.0.16",
    "@react-hookz/web": "^23.0.0",
    "@tanstack/react-query": "catalog:",
    "@tanstack/react-query-devtools": "catalog:",
    "@urql/exchange-graphcache": "^7.1.2",
    "@urql/introspection": "^1.1.0",
    "accounting-js": "^1.1.1",
    "ag-grid-community": "catalog:",
    "ag-grid-enterprise": "catalog:",
    "ag-grid-react": "catalog:",
    "antd": "~5.3.0",
    "dompurify": "^3.0.5",
    "graphql": "catalog:",
    "graphql-tag": "^2.12.6",
    "handlebars": "^4.7.8",
    "html-react-parser": "^5.0.11",
    "interactjs": "^1.10.19",
    "lodash": "^4.17.21",
    "loglevel": "^1.8.1",
    "moment": "^2.30.1",
    "moment-timezone": "^0.5.40",
    "omit-deep-lodash": "^1.1.7",
    "react": "catalog:react18",
    "react-app-polyfill": "^3.0.0",
    "react-contexify": "^6.0.0",
    "react-dom": "catalog:react18",
    "react-error-boundary": "^4.0.12",
    "react-hotkeys-hook": "^4.3.8",
    "react-json-view-lite": "^0.9.6",
    "react-resizable": "^3.0.5",
    "react-router-dom": "^5.3.4",
    "react-split-grid": "^1.0.4",
    "react-virtualized-auto-sizer": "1.0.18",
    "rxjs": "^7.8.1",
    "slugid": "^3.2.0",
    "urql": "catalog:",
    "uuid": "^9.0.1",
    "wait-promise": "^0.4.1"
  },
  "devDependencies": {
    "@alaffia-technology-solutions/eslint-config": "workspace:*",
    "@alaffia-technology-solutions/jest-config": "workspace:*",
    "@alaffia-technology-solutions/prettier-config": "workspace:*",
    "@alaffia-technology-solutions/tailwind-config": "workspace:*",
    "@alaffia-technology-solutions/tsconfig": "workspace:*",
    "@babel/core": "^7.22.8",
    "@babel/plugin-transform-react-jsx": "^7.20.13",
    "@babel/preset-typescript": "^7.22.5",
    "@emotion/babel-plugin-jsx-pragmatic": "^0.2.1",
    "@graphql-codegen/cli": "^5.0.0",
    "@graphql-codegen/client-preset": "^4.1.0",
    "@graphql-codegen/urql-introspection": "^3.0.0",
    "@parcel/babel-preset-env": "2.9.3",
    "@parcel/config-default": "^2.10.3",
    "@parcel/reporter-dev-server": "^2.12.0",
    "@testing-library/react-hooks": "^8.0.1",
    "@types/dompurify": "^3.0.2",
    "@types/lodash": "^4.14.202",
    "@types/omit-deep-lodash": "^1.1.1",
    "@types/react": "catalog:react18",
    "@types/react-dom": "catalog:react18",
    "@types/react-resizable": "^3.0.4",
    "@types/react-router-dom": "^5.3.3",
    "@types/react-test-renderer": "^18.0.3",
    "@types/uuid": "^9.0.7",
    "@types/wait-promise": "^0.4.0",
    "babel-jest": "^29.6.1",
    "babel-plugin-macros": "^3.1.0",
    "babel-plugin-transform-import-meta": "^2.2.1",
    "babel-plugin-twin": "^1.1.0",
    "env-cmd": "^10.1.0",
    "eslint": "catalog:",
    "immer": "^10.0.2",
    "parcel": "^2.12.0",
    "parcel-reporter-static-files-copy": "^1.5.3",
    "parcel-resolver-ignore": "^2.2.0",
    "postcss": "^8.4.35",
    "postcss-import": "^16.1.0",
    "postcss-url": "^10.1.3",
    "prettier": "catalog:",
    "punycode": "^1.4.1",
    "react-test-renderer": "18.2.0",
    "serve-static": "^1.16.0",
    "tailwind-scrollbar": "^3.0.0",
    "tailwindcss": "catalog:",
    "timers-browserify": "^2.0.11",
    "twin.macro": "^3.1.0",
    "typescript": "catalog:"
  },
  "parcelIgnore": [
    "/public/foxit/.+"
  ],
  "staticFiles": [
    {
      "staticPath": "../../node_modules/@foxitsoftware/foxit-pdf-sdk-for-web-library-full/lib",
      "staticOutPath": "public/foxit/lib"
    },
    {
      "staticPath": "public/foxit/external",
      "staticOutPath": "public/foxit/external"
    }
  ],
  "prettier": "@alaffia-technology-solutions/prettier-config",
  "alias": {
    "process": false
  },
  "@parcel/transformer-css": {
    "errorRecovery": true
  }
}
