import type { RefObject } from "react";
import { useEffect } from "react";

import { usePDFStore } from "../../../providers";

export const useInitializePDFCtrl = (elementRef: RefObject<HTMLElement>) => {
  const { data: pdfViewerCtrl, isServer } = usePDFStore((state) => ({
    init: state.init,
    pdfViewerState: state.pdfViewerState,
    pdfViewer: state.pdfViewer,
    setCurrentPageIndex: state.setCurrentPageIndex,
    events: state.events,
  }));

  useEffect(() => {
    if (isServer) {
      return;
    }

    if (elementRef.current && pdfViewerCtrl.init) {
      void pdfViewerCtrl.init(elementRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef, isServer]);

  useEffect(() => {
    if (isServer) {
      return;
    }

    if (pdfViewerCtrl) {
      // Make sure the active page is saved on unmount
      return () => {
        if (pdfViewerCtrl) {
          const { pdfViewer, setCurrentPageIndex } = pdfViewerCtrl;
          const currentPage = pdfViewer
            ?.getPDFDocRender()
            ?.getCurrentPageIndex();

          if (currentPage) {
            setCurrentPageIndex(currentPage);
          }
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { pdfViewerCtrl, isServer };
};
