/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, message, Table, Tooltip } from "antd";
import moment from "moment-timezone";
import { gql, useQuery } from "urql";

import { CreateUserModal } from "./createUserModal";
import { OnboardUserModal } from "./onboardUserModal";

const query = gql`
  query getUsers {
    auditorUsers(orderBy: CREATED_AT_DESC) {
      nodes {
        id
        createdAt
        email
        userType
        firstName
        lastName
        provider {
          name
          id
        }
        insurerUsers {
          nodes {
            id
            auditorUserId
            insurerId
          }
        }
        providerUserFacilities {
          nodes {
            id
            provider {
              id
              name
            }
          }
        }
        userInviteCreateds(orderBy: CREATED_AT_DESC) {
          nodes {
            id
            messageId
            auditorUserId
          }
        }
        messageSentsByRecipientUserId(orderBy: CREATED_AT_DESC, first: 1) {
          nodes {
            id
            createdAt
          }
        }
      }
    }
  }
`;

const Users = () => {
  const [{ data, fetching }, reexecuteQuery] = useQuery({
    query,
    requestPolicy: "network-only",
  });

  const [createUserModalVisible, setCreateUserModalVisible] = useState(false);
  const [onboardModalVisible, setOnboardModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const successMessage = (text: any) => message.success(text, 15);
  const errorMessage = (text: any) => message.error(text, 15);

  useEffect(() => {
    console.log("refreshing....");
    reexecuteQuery({ requestPolicy: "network-only" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const userColumns = [
    {
      title: "Name",
      render: ({ firstName, lastName }: any) => `${firstName} ${lastName}`,
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Type",
      dataIndex: "userType",
    },
    {
      title: "Facilities",
      render: ({ provider, providerUserFacilities }: any) => {
        if (provider) {
          return `${provider.name} (${provider.id})`;
        }
        if (providerUserFacilities) {
          return providerUserFacilities.nodes.map(
            ({ provider: { name, id } }: any) => `${name} (${id})\n\n`,
          );
        }
        return "-";
      },
    },
    {
      title: "Created",
      render: ({ createdAt }: any) => moment.utc(createdAt).format("MM/DD/YY"),
    },
    {
      title: "Onboarded",
      render: ({ messageSentsByRecipientUserId }: any) =>
        messageSentsByRecipientUserId.nodes.length !== 0
          ? moment
              .utc(messageSentsByRecipientUserId.nodes[0].createdAt)
              .format("MM/DD/YY")
          : "-",
    },
    {
      title: "Actions",
      render: (user: any) => (
        <div>
          <Button
            onClick={() => {
              setSelectedUser(user);
              setOnboardModalVisible(true);
            }}
          >
            Onboard
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Tooltip title="Create New User">
        <Button
          type="primary"
          shape="circle"
          icon={<PlusOutlined />}
          onClick={() => setCreateUserModalVisible(true)}
        />
      </Tooltip>
      <Table
        dataSource={data ? data.auditorUsers.nodes : []}
        columns={userColumns}
        loading={fetching}
        rowKey={"id"}
      />
      <CreateUserModal
        visible={createUserModalVisible}
        close={() => setCreateUserModalVisible(false)}
        refresh={() => setRefresh(true)}
      />
      <OnboardUserModal
        user={selectedUser}
        visible={onboardModalVisible}
        close={() => setOnboardModalVisible(false)}
        refresh={() => setRefresh(true)}
        success={successMessage}
        error={errorMessage}
      />
    </div>
  );
};

export { Users };
