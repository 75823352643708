import type { ComponentProps, ReactNode } from "react";
import { useState } from "react";

import { cn } from "@alaffia-technology-solutions/tailwind-utils";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@alaffia-technology-solutions/ui";

export interface PDFSidebarTab<TTabKey extends string = string> {
  key: TTabKey;
  icon: ReactNode;
  title: string;
  content: ReactNode;
  triggerProps?: Partial<ComponentProps<typeof TabsTrigger>>;
}

interface PDFSidebarProps<TTabKey extends string> {
  tabs: PDFSidebarTab<TTabKey>[];
  selectedTab: TTabKey | null;
  onTabChange: (tab: TTabKey | null) => void;
}

const collapsedTabsValue = "";

export const PDFSidebar = <TTabKey extends string>({
  tabs,
  selectedTab,
  onTabChange,
}: PDFSidebarProps<TTabKey>) => {
  return (
    <Tabs
      orientation="vertical"
      className="af-flex"
      // collapsed tabs are represented by an empty string `""`
      value={selectedTab ?? collapsedTabsValue}
      onValueChange={() => {
        // Ignore onValueChange to support collapsable tabs (via onClick handler)
      }}
    >
      <TabsList className="af-rounded-none af-border-r af-border-border af-bg-transparent af-py-0 af-pl-0">
        <TooltipProvider>
          {tabs.map((tab) => (
            <Tooltip key={tab.key}>
              <TabsTrigger
                value={tab.key}
                className={cn("!af-p-3", tab.triggerProps?.className)}
                asChild
                onClick={() => {
                  const newSelectedTab =
                    tab.key === selectedTab ? null : tab.key;

                  onTabChange(newSelectedTab);
                }}
                {...tab.triggerProps}
              >
                <TooltipTrigger>{tab.icon}</TooltipTrigger>
              </TabsTrigger>
              <TooltipContent>{tab.title}</TooltipContent>
            </Tooltip>
          ))}
        </TooltipProvider>
      </TabsList>

      {tabs.map((tab) => (
        <TabsContent
          key={tab.key}
          className="af-w-[200px]"
          value={tab.key}
          hidden={tab.key !== selectedTab}
          forceMount
        >
          {tab.content}
        </TabsContent>
      ))}
    </Tabs>
  );
};

interface UsePDFSidebarTabsOptions<TTabKey extends string> {
  defaultSelectedTabKey?: TTabKey | null;
}

export const usePDFSidebarTabs = <TTabKey extends string>(
  tabs: PDFSidebarTab<TTabKey>[],
  {
    defaultSelectedTabKey = tabs[0]?.key ?? null,
  }: UsePDFSidebarTabsOptions<TTabKey> = {},
) => {
  return useState<TTabKey | null>(defaultSelectedTabKey);
};
