/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import type { FC, ReactNode } from "react";
import { useCallback, useEffect, useState } from "react";
import { message } from "antd";

import type {
  LoginFailResult,
  LoginResult,
} from "@alaffia-technology-solutions/client-sdk";

import { ErrorBoundary } from "../common/error/errorBoundary";
import { useLog } from "../common/log/useLog";
import { Header } from "../components/app/header";
import { LoginModal } from "./loginModal";
import type { ChallengeArgs } from "./types";
import { isChangePwdArgs, isMfaCodeArgs } from "./types";
import { useAlaffiaClient } from "./useAlaffiaClient";
import { useLoginStatus } from "./useLoginStatus";

const errMsgForErrResp = (resp: LoginFailResult) => {
  return (
    resp?.responseBody?.error_description ??
    resp?.responseBody?.error?.message ??
    "Login failed - verify credentials and try again"
  );
};

export const AlaffiaLogin: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const log = useLog("AlaffiaLogin");

  const alaffiaClient = useAlaffiaClient();
  const { loggedIn, hasLoggedIn, userId } = useLoginStatus();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [lastError, setLastError] = useState<undefined | string>(undefined);
  const [pendingChallenge, setPendingChallenge] = useState<null | LoginResult>(
    null,
  );

  useEffect(() => {
    if (!isOpen && loggedIn !== null && !loggedIn && !hasLoggedIn) {
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    log.trace("loginStatus effect, loggedIn:", {
      loggedIn,
      hasLoggedIn,
      userId,
    });
    if (loggedIn === null) {
      // do nothing - we haven't loaded the client yet to determine if a login can be restored...
    } else if (loggedIn || hasLoggedIn) {
      log.debug("logged in TRUE:", loggedIn);
      setPendingChallenge(null);
      setLastError(undefined);
      setIsOpen(false);
    } else {
      log.debug("logged in FALSE:", loggedIn);
      setPendingChallenge(null);
      setLastError(undefined);
      setIsOpen(true);
    }
    return () => {
      log.debug("loginStatus EFFECT cleanup", {
        loggedIn,
        hasLoggedIn,
        userId,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, hasLoggedIn, userId]);

  const login = useCallback(
    ({ user, pass }: { user: string; pass: string }) => {
      setIsOpen(false);
      alaffiaClient.auth
        .login({
          password: pass,
          username: user,
        })
        .then((r: LoginResult) => {
          if (r.predicates.isLoginSuccess(r)) {
            log.trace("login success", r);
            setLastError(undefined);
            setPendingChallenge(null);
            setIsOpen(false);
          } else if (r.predicates.isLoginFail(r)) {
            log.trace("login failed at user/pass", r);
            setLastError(errMsgForErrResp(r));
            setIsOpen(true);
          } else {
            setLastError(undefined);
            setPendingChallenge(r);
            setIsOpen(true);
          }
        })
        .catch((e) => {
          log.error("Login error", e);
          void message.error(
            "Login failed - error communicating with server \n" +
              (e.message ?? "(unknown)"),
          );
          setIsOpen(true);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [alaffiaClient],
  );

  const challenge = useCallback(
    (args: ChallengeArgs) => {
      setIsOpen(false);
      if (!pendingChallenge) {
        throw new Error("No pending login challenge!");
      } else {
        const fn = pendingChallenge?.predicates.isLoginChallenge(
          pendingChallenge,
        )
          ? async () => {
              if (isMfaCodeArgs(args)) {
                return alaffiaClient.auth.loginChallenge({
                  challengeResponse: pendingChallenge,
                  code: args.code,
                });
              } else {
                throw new Error("MFA code is required");
              }
            }
          : pendingChallenge?.predicates?.isConfigureMfa(pendingChallenge)
          ? async () => {
              if (isMfaCodeArgs(args)) {
                return alaffiaClient.auth.configureMfa(
                  pendingChallenge,
                  args.code,
                );
              } else {
                throw new Error("MFA code is required");
              }
            }
          : pendingChallenge?.predicates?.isForceChangePassword(
              pendingChallenge,
            )
          ? async () => {
              if (isChangePwdArgs(args)) {
                return alaffiaClient.auth.forceChangePassword(
                  pendingChallenge,
                  args.oldPassword,
                  args.newPassword,
                );
              } else {
                throw new Error(`All fields are required to change password`);
              }
            }
          : async () => {
              return Promise.resolve().then(() => {
                throw new Error("Unknown pending challenge type");
              });
            };

        fn()
          .then((r: LoginResult) => {
            if (r.predicates.isLoginSuccess(r)) {
              log.trace("login success", r);
              setLastError(undefined);
              setPendingChallenge(null);
              setIsOpen(false);
            } else if (r.predicates.isLoginFail(r)) {
              log.error("login failed at user/pass", r);
              setLastError(errMsgForErrResp(r));
              setIsOpen(true);
            } else {
              setLastError(undefined);
              setPendingChallenge(r);
              setIsOpen(true);
            }
          })
          .catch((e) => {
            log.error("login error", e);
            void message.error(
              "Login failed - error communicating with server \n" +
                (e.message ?? "(unknown)"),
            );
            setIsOpen(true);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [alaffiaClient, pendingChallenge],
  );

  return loggedIn === null ? (
    <div tw="af-w-full af-h-full af-max-h-full af-max-w-full af-overflow-y-auto af-bg-antd-lt-colorBgContainer dark:af-bg-antd-dk-colorBgContainer"></div>
  ) : !loggedIn && !hasLoggedIn ? (
    <div tw="af-fixed af-inset-0">
      <Header />
      <ErrorBoundary boundaryDescription="AlaffiaLogin [never logged in]">
        <LoginModal
          loginResult={pendingChallenge}
          login={login}
          isOpen={isOpen}
          setIsOpen={(b: boolean) => setIsOpen(b)}
          challenge={challenge}
          lastError={lastError}
        ></LoginModal>
      </ErrorBoundary>
    </div>
  ) : (
    <div tw="af-w-full af-h-full af-max-h-full af-max-w-full af-overflow-y-auto af-bg-antd-lt-colorBgContainer dark:af-bg-antd-dk-colorBgContainer">
      {children}

      <ErrorBoundary boundaryDescription="AlaffiaLogin [not logged in]">
        <LoginModal
          loginResult={pendingChallenge}
          login={login}
          isOpen={isOpen}
          setIsOpen={(b: boolean) => setIsOpen(b)}
          challenge={challenge}
          lastError={lastError}
        ></LoginModal>
      </ErrorBoundary>
    </div>
  );
};
