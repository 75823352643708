import * as React from "react";
import { useState } from "react";
import { IconDotsVertical } from "@tabler/icons-react";

import type { File } from "@alaffia-technology-solutions/schema";
import {
  Button,
  Checkbox,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Separator,
  Skeleton,
} from "@alaffia-technology-solutions/ui";

import { Files } from "./Files";

interface UseFilesPanelControlsProps {
  externalCollapse?: () => void;
  externalExpand?: () => void;
  externalIsExpanded?: boolean;
}

const useFilesPanelControls = ({
  externalCollapse,
  externalExpand,
  externalIsExpanded,
}: UseFilesPanelControlsProps) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return {
    collapse: externalCollapse ?? (() => setIsExpanded(false)),
    expand: externalExpand ?? (() => setIsExpanded(true)),
    isExpanded: externalIsExpanded ?? isExpanded,
  };
};

type FilesProps = React.ComponentPropsWithoutRef<typeof Files>;
type FilesPropsNoControls = Omit<
  FilesProps,
  "collapse" | "expand" | "isExpanded"
>;

interface FilesPanelProps extends FilesPropsNoControls {
  files?: File[];
  onFilePreview: (file: File) => void;
  actions?: React.ReactNode;
  expandedActions?: React.ReactNode;
  collapse?: FilesProps["collapse"];
  expand?: FilesProps["expand"];
  isExpanded?: FilesProps["isExpanded"];
  position: "left" | "right";
}

export const FilesPanel = React.forwardRef<HTMLDivElement, FilesPanelProps>(
  (
    {
      files,
      onFilePreview,
      expand: externalExpand,
      collapse: externalCollapse,
      isExpanded: externalIsExpanded,
      position,
      ...props
    },
    ref,
  ) => {
    const { collapse, expand, isExpanded } = useFilesPanelControls({
      externalCollapse,
      externalExpand,
      externalIsExpanded,
    });

    return (
      <BaseFilesPanel
        ref={ref}
        expand={expand}
        collapse={collapse}
        isExpanded={isExpanded}
        position={position}
        {...props}
      >
        <Files.List>
          {files?.map((file) => (
            <Files.Item
              key={file.id}
              file={file}
              onFilePreview={() => onFilePreview(file)}
            />
          )) ?? null}
        </Files.List>
      </BaseFilesPanel>
    );
  },
);
FilesPanel.displayName = "FilesPanel";

interface ItemMenuProps {
  itemActions: {
    name: string;
    onClick: (id: string) => void;
  }[];
  id: string;
}
const ItemMenu: React.FC<ItemMenuProps> = ({ itemActions, id }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size="icon" variant="ghost" className="af-h-auto">
          <IconDotsVertical className="af-h-4 af-w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {itemActions.map(({ name, onClick }) => (
          <DropdownMenuItem key={name} onClick={() => onClick(id)}>
            {name}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

interface SelectableFilesPanelProps extends FilesPanelProps {
  selectedFileIds?: string[];
  onFileSelection?: (fileId: string, selected: boolean) => void;
  itemActions?: {
    name: string;
    onClick: (id: string) => void;
  }[];
}

export const SelectableFilesPanel = React.forwardRef<
  HTMLDivElement,
  SelectableFilesPanelProps
>(
  (
    {
      files,
      onFilePreview,
      selectedFileIds,
      onFileSelection,
      itemActions,
      expand: externalExpand,
      collapse: externalCollapse,
      isExpanded: externalIsExpanded,
      expandedActions,
      ...props
    },
    ref,
  ) => {
    const { collapse, expand, isExpanded } = useFilesPanelControls({
      externalCollapse,
      externalExpand,
      externalIsExpanded,
    });

    const selectedFiles = files?.filter(
      (file) => selectedFileIds?.includes(file.id),
    );
    const unselectedFiles = files?.filter(
      (file) => !selectedFileIds?.includes(file.id),
    );

    return (
      <BaseFilesPanel
        loading={!files}
        numberOfSelectedFiles={selectedFiles?.length ?? 0}
        {...props}
        expand={expand}
        collapse={collapse}
        isExpanded={isExpanded}
        expandedActions={expandedActions}
        ref={ref}
      >
        <div className="af-grid af-gap-6">
          {(selectedFiles?.length ?? 0) > 0 && (
            <Files.Section title="Selected">
              <Files.List className="af-rounded-lg af-bg-primary-100">
                {selectedFiles?.map((file) => (
                  <Files.Item
                    key={file.id}
                    file={file}
                    onFilePreview={() => onFilePreview(file)}
                    showBefore={selectedFileIds?.includes(file.id)}
                    before={
                      <Checkbox
                        className="af-mr-2"
                        disabled={!file.readyToSearch}
                        variant="info"
                        checked={selectedFileIds?.includes(file.id)}
                        onCheckedChange={(selected) =>
                          onFileSelection?.(file.id, selected === true)
                        }
                      />
                    }
                    after={
                      (itemActions?.length && (
                        <ItemMenu itemActions={itemActions} id={file.id} />
                      )) ??
                      null
                    }
                  />
                )) ?? null}
              </Files.List>
            </Files.Section>
          )}

          {(unselectedFiles?.length ?? 0) > 0 && (
            <Files.Section title="All">
              <Files.List>
                {unselectedFiles?.map((file) => (
                  <Files.Item
                    key={file.id}
                    file={file}
                    onFilePreview={() => onFilePreview(file)}
                    before={
                      <Checkbox
                        className="af-mr-2"
                        disabled={!file.readyToSearch}
                        variant="info"
                        checked={selectedFileIds?.includes(file.id)}
                        onCheckedChange={(selected) =>
                          onFileSelection?.(file.id, selected === true)
                        }
                      />
                    }
                    after={
                      (itemActions?.length && (
                        <ItemMenu itemActions={itemActions} id={file.id} />
                      )) ??
                      null
                    }
                  />
                )) ?? null}
              </Files.List>
            </Files.Section>
          )}
        </div>
      </BaseFilesPanel>
    );
  },
);
SelectableFilesPanel.displayName = "SelectableFilesPanel";

interface BaseFilesPanelProps
  extends React.ComponentPropsWithoutRef<typeof Files> {
  loading?: boolean;
  children: React.ReactNode;
  expandedActions?: React.ReactNode;
  position: "left" | "right";
}

const BaseFilesPanel = React.forwardRef<HTMLDivElement, BaseFilesPanelProps>(
  (
    { children, actions, expandedActions, loading, position, ...props },
    ref,
  ) => {
    return (
      <Files
        ref={ref}
        position={position}
        expandedActions={expandedActions}
        {...props}
      >
        <Files.Header position={position} />
        <Files.Body>
          {!loading ? children : <FilesListSkeleton />}
          {actions}
        </Files.Body>
      </Files>
    );
  },
);
BaseFilesPanel.displayName = "BaseFilesPanel";

function FileSkeleton() {
  return (
    <li className="af-flex af-flex-col af-gap-2 af-border-b af-border-border af-px-2 af-py-5">
      <Skeleton className="af-h-4 af-w-1/2" />
      <div className="af-flex af-h-3 af-items-center af-gap-2">
        <Skeleton className="af-h-4 af-w-6 af-border-r af-border-border af-pr-2" />
        <Separator orientation="vertical" />
        <Skeleton className="af-h-4 af-w-4/6" />
      </div>
    </li>
  );
}

function FilesListSkeleton() {
  return (
    <ul className="af-list-none af-p-0">
      <FileSkeleton />
      <FileSkeleton />
      <FileSkeleton />
    </ul>
  );
}
