import * as React from "react";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import type { InitialEditorStateType } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { $dfs, $filter } from "@lexical/utils";
import type { EditorState, LexicalEditor } from "lexical";
import { $getRoot, CLEAR_EDITOR_COMMAND } from "lexical";

import { cn } from "@alaffia-technology-solutions/tailwind-utils";
import { textareaVariants } from "@alaffia-technology-solutions/ui";

import { useChatbox } from "./ChatboxProvider";
import { DefaultChatboxSubmitButton } from "./DefaultChatboxSubmitButton";
import { LexicalComposer } from "./LexicalComposer";
import { MentionsMenu, MentionsMenuItem } from "./Mentions";
import { EditorCapturePlugin } from "./plugins/EditorCapturePlugin";
import { $isKeywordNode, KeywordsPlugin } from "./plugins/KeywordsPlugin";
import type { MentionsPluginProps } from "./plugins/MentionsPlugin";
import { MentionsPlugin } from "./plugins/MentionsPlugin";
import { SubmitPlugin } from "./plugins/SubmitPlugin";

export interface MessageInputProps {
  onSubmit?: (
    textContent: string,
    editorState: string,
    keywords: string[],
  ) => void;
  disabled?: boolean;
  actions?: React.ReactNode;
  submitButton?: React.ReactNode;
  mentionsMenuAnchor?: EditorProps["mentionsMenuAnchor"];
  mentionsMenuComponent?: EditorProps["mentionsMenuComponent"];
  mentionsMenuItemComponent?: EditorProps["mentionsMenuItemComponent"];
  onMentionAdded?: EditorProps["onMentionAdded"];
  onMentionRemoved?: EditorProps["onMentionRemoved"];
  initialEditorState?: EditorProps["initialEditorState"];
  placeholder?: EditorProps["placeholder"];
  filesMentions?: EditorProps["filesMentions"];
  topContent?: React.ReactNode;
}

interface FileMention {
  id: string;
  name: string;
}

interface FileMentionItem {
  value: string;
  id: string;
  [key: string]: string | number | boolean;
}

export function MessageInput({
  filesMentions,
  onSubmit,
  disabled,
  actions,
  submitButton,
  mentionsMenuAnchor,
  mentionsMenuComponent,
  mentionsMenuItemComponent,
  onMentionAdded,
  onMentionRemoved,
  placeholder,
  initialEditorState,
  topContent,
  ...props
}: MessageInputProps) {
  const { editorRef, isEditorEmpty, setIsEditorEmpty } = useChatbox();

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (!editorRef.current) {
      return;
    }

    if (isEditorEmpty || disabled) {
      return;
    }

    const textContent = editorRef.current
      .getEditorState()
      .read(() => $getRoot().getTextContent());
    const editorState = JSON.stringify(
      editorRef.current.getEditorState().toJSON(),
    );
    const keywords = editorRef.current.getEditorState().read(() =>
      $filter(
        $dfs().map(({ node }) => node),
        (node) => ($isKeywordNode(node) ? node : null),
      ).map((keywordNode) => keywordNode.getTextContent()),
    );

    editorRef.current?.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
    return onSubmit?.(textContent, editorState, keywords);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={cn(
        textareaVariants(),
        "af-flex af-min-h-[fit-content] af-flex-col af-justify-between af-gap-0.5",
      )}
      {...props}
    >
      <Editor
        ref={editorRef}
        mentionsMenuAnchor={mentionsMenuAnchor}
        mentionsMenuComponent={mentionsMenuComponent}
        mentionsMenuItemComponent={mentionsMenuItemComponent}
        onMentionAdded={onMentionAdded}
        onMentionRemoved={onMentionRemoved}
        initialEditorState={initialEditorState}
        filesMentions={filesMentions}
        placeholder={placeholder}
        onSubmit={() => handleSubmit()}
        topContent={topContent}
        onChange={(editorState) => {
          const isEditorEmpty = editorState.read(() => {
            const root = $getRoot();
            const isEmpty =
              root.getChildrenSize() === 1 &&
              root?.getFirstChild()?.getTextContent()?.trim().length === 0;

            return isEmpty;
          });
          setIsEditorEmpty(isEditorEmpty);
        }}
      />
      <div className="af-flex af-items-center af-justify-between af-rounded-lg af-bg-white">
        <div className="af-flex af-justify-between af-gap-2">{actions}</div>
        <div>
          {submitButton ?? <DefaultChatboxSubmitButton disabled={disabled} />}
        </div>
      </div>
    </form>
  );
}

type FileMentionsPluginProps = MentionsPluginProps<FileMentionItem>;

interface EditorProps {
  filesMentions?: FileMention[];
  onSubmit: (editor: LexicalEditor) => void;
  initialEditorState?: InitialEditorStateType;
  mentionsMenuAnchor?: FileMentionsPluginProps["menuAnchor"];
  mentionsMenuComponent?: FileMentionsPluginProps["menuComponent"];
  mentionsMenuItemComponent?: FileMentionsPluginProps["menuItemComponent"];
  onMentionAdded?: FileMentionsPluginProps["onMentionAdded"];
  onMentionRemoved?: FileMentionsPluginProps["onMentionRemoved"];
  onChange?: (editorState: EditorState, editor: LexicalEditor) => void;
  placeholder?: React.ReactNode;
  topContent?: React.ReactNode;
}

const Editor = React.forwardRef<LexicalEditor, EditorProps>(
  (
    {
      filesMentions,
      onSubmit,
      initialEditorState,
      mentionsMenuAnchor,
      mentionsMenuComponent = MentionsMenu,
      mentionsMenuItemComponent = MentionsMenuItem,
      onMentionAdded,
      onMentionRemoved,
      onChange = () => null,
      topContent,
      placeholder = "Ask question or make a request...",
    },
    ref,
  ) => {
    const wrapperRef = React.useRef<HTMLDivElement>(null);
    const mentionsItems = React.useMemo(
      () =>
        filesMentions?.map((file) => ({
          id: file.id,
          value: file.name,
        })) ?? [],
      [filesMentions],
    );

    const initialConfig = {
      namespace: "chatbox",
      editorState: initialEditorState,
    };

    return (
      <>
        {topContent}
        <div
          ref={wrapperRef}
          className="af-relative af-max-h-64 af-flex-1 af-overflow-auto af-p-0 af-text-sm"
        >
          <LexicalComposer initialConfig={initialConfig}>
            <PlainTextPlugin
              contentEditable={
                <ContentEditable
                  data-testid="message-editor"
                  className="af-min-h-[83px] af-pr-5 af-pt-2 af-text-gray-900 af-outline-none"
                />
              }
              placeholder={
                <div className="af-pointer-events-none af-absolute af-left-0 af-top-2 af-text-gray-500">
                  {placeholder}
                </div>
              }
              ErrorBoundary={LexicalErrorBoundary}
            />
            <EditorCapturePlugin ref={ref} />
            <OnChangePlugin onChange={onChange} />
            <ClearEditorPlugin />
            <SubmitPlugin onSubmit={onSubmit} />
            <MentionsPlugin
              menuAnchor={mentionsMenuAnchor}
              menuComponent={mentionsMenuComponent}
              menuItemComponent={mentionsMenuItemComponent}
              items={mentionsItems}
              onMentionAdded={onMentionAdded}
              onMentionRemoved={onMentionRemoved}
            />
            <KeywordsPlugin />
          </LexicalComposer>
        </div>
      </>
    );
  },
);
Editor.displayName = "ChatboxEditor";
