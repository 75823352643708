import { forwardRef } from "react";
import { IconFiles } from "@tabler/icons-react";

import { Button } from "@alaffia-technology-solutions/ui";

interface MessageInputTopContentProps {
  contextFileNames: string[];
  expandPanel: () => void;
  clearFileSelection: () => void;
}
export const MessageInputTopContent = forwardRef<
  HTMLDivElement,
  MessageInputTopContentProps
>(({ contextFileNames, expandPanel, clearFileSelection }, ref) => (
  <div ref={ref} className="af-flex af-items-center af-gap-2">
    <Button
      variant="outline"
      size="sm"
      className="af-gap-2 af-self-start af-border-[1px] af-border-gray-300 af-bg-gray-100 af-p-[6px] af-text-xs af-font-medium af-text-neutral-900"
      title={contextFileNames?.join(", ")}
      onClick={expandPanel}
    >
      <div className="af-flex af-min-h-4 af-min-w-4 af-items-center af-justify-center af-rounded-sm af-bg-info-500 af-text-[10px] af-leading-4 af-text-white">
        {contextFileNames?.length}
      </div>
      <IconFiles className="af-h-4 af-w-4 af-text-info-500" />
      Attached files
    </Button>

    {contextFileNames?.length > 0 && (
      <Button
        variant="outline"
        size="sm"
        className="af-border-[1px] af-border-gray-500 af-p-[6px] af-text-xs af-font-semibold af-text-gray-500"
        onClick={clearFileSelection}
      >
        Clear
      </Button>
    )}
  </div>
));
