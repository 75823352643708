/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */

/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
import type { ForwardRefExoticComponent, Ref, RefAttributes } from "react";
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import { Button, Input, message, Switch, Tooltip } from "antd";
import type { Dictionary } from "lodash";

import type { BatchClaim, Claim, Finding } from "../../../../../gql/graphql";
import { createUpdateAuditFindingErrorHandler } from "../../../claimWorkspace/shared";

import "ag-grid-enterprise";

import {
  ArrowsAltOutlined,
  VerticalAlignMiddleOutlined,
} from "@ant-design/icons";
import type {
  CellFocusedEvent,
  ColDef,
  DetailGridInfo,
  GetRowIdFunc,
  GetRowIdParams,
  IRowNode,
  IsRowMaster,
  IsRowSelectable,
  RowClassParams,
  RowClassRules,
  RowClickedEvent,
  RowStyle,
} from "ag-grid-community";
import _log from "loglevel";
import { createPortal } from "react-dom";
import { useRouteMatch } from "react-router-dom";
import tw, { css } from "twin.macro";
import { every } from "wait-promise";

import { useHashParams } from "../../../../../common/navigation/useHashParam";
import { toSlug } from "../../../../../common/navigation/utils";
import { LoadingOverlay } from "../../../../../common/table/ag/loadingOverlay";
import { NoDataOverlay } from "../../../../../common/table/ag/noDataOverlay";
import { dateToUtcLocaleDateString } from "../../../../../common/util/date/dateToUtcLocaleDateString";
import { UserContext } from "../../../../context/user";
import { useDenialCodes } from "../../../queries/denialCodes/useDenialCodes";
import { ClaimLineTableStatusBar } from "../../../shared/components/claimLine/statusbarCreateFindingsButton";
import {
  getRuleForAutodorFinding,
  getRuleLabelForFinding,
} from "../../../shared/components/finding/autodorFindingUtils";
import { createAuditFindingDetailsTableRendererParams } from "../../../shared/components/finding/detailsTableRendererProps";
import { getClaimLineTableRowClassRules } from "../../../shared/style/claimLineTableRowClassRules";
import { ClaimLineTableStyleProvider } from "../../../shared/style/claimLineTableStyleProvider";
import { isAutodorFinding } from "../../../util/findingType";
import { asMoney } from "../../../util/format";
import { orZero } from "../../../util/orZero";
import { acceptsFindings } from "../../createAuditFinding/auditFindingUtil";
import { FindingContext } from "../../findingEdit/findingProvider";
import type { UserType } from "../../findingEdit/types";
import { getIbTableColumns, ibTableDefaultColumnDef } from "./ibTableColumns";
import { getIbTableSideBar } from "./ibTableSidebar";
import type { ItemizedBillLineOrNull } from "./ibTableUtil";
import { buildIbGridLines, isNonNullIbLinesArray } from "./ibTableUtil";
import type { FindingDisplayAugmented, IBGridLine } from "./types";

const log = _log.getLogger("ibTable");

function getDateRange(startDate: string, endDate: string) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const dateArray = [];

  for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
    const formattedDate = dateToUtcLocaleDateString(date); // date.toLocaleDateString();
    dateArray.push(formattedDate);
  }

  return dateArray;
}

export type IbTableProps = {
  width: number;
  height: number;
  claim: Claim;
  batchClaim: BatchClaim;
  loading: boolean;
  setCreatingAuditFinding: any;
  setDecliningAuditFinding: any;
  navigateToIbinRow: ({
    rowId,
    findingId,
  }: {
    rowId: string;
    findingId: string;
  }) => void;
  // Improvement: we're mixing metaphors here, either this component manages focused row or the parent does
  // onRowClicked?: (ibLine: IBGridLine) => void;
  readOnly: boolean;
  canCreateFindings: boolean;
  tabToolbarRef: any;
};

type GridData = {
  ibLines: IBGridLine[] | undefined;
  findings: Dictionary<Finding>;
  gridContext: any;
  renderCount: number;
  prevRenderCount: number;
};

export type IbTableReadyListener = () => void;
export type IbTableDeregisterListener = () => void;
export type IbTableFunctions = {
  scrollToRowId(rowId: string | null, childRowId: string | null): void;
  onReady(listener: IbTableReadyListener): IbTableDeregisterListener;
  isGridReady(): boolean;
};

export const IbTable: ForwardRefExoticComponent<
  IbTableProps & RefAttributes<IbTableFunctions>
> = forwardRef<IbTableFunctions, IbTableProps>(
  (
    {
      width,
      height,
      claim,
      batchClaim,
      loading,
      setCreatingAuditFinding,
      setDecliningAuditFinding,
      navigateToIbinRow,
      readOnly,
      tabToolbarRef,
    },
    ref: Ref<IbTableFunctions>,
  ) => {
    const user: any = useContext(UserContext);

    const userType: UserType = useMemo(() => user!.userType, []);

    const isAdmin = useMemo(
      () => userType === ("ADMINISTRATOR" as UserType),
      [],
    );

    const isInternal = useMemo(
      () =>
        userType === ("ADMINISTRATOR" as UserType) ||
        userType === ("AUDITOR" as UserType),
      [],
    );

    const viewingReport =
      useRouteMatch("/reports/:auditFindingReportId/workspace") !== null;

    const showFindings = useMemo(
      () => userType !== ("PROVIDER" as UserType) || viewingReport,
      [],
    );

    const { showAutodorFindingsDefault, canViewAutodorFindings } =
      useContext(FindingContext);
    const [showAutodorFindings, setShowAutodorFindings] = useState<boolean>(
      showAutodorFindingsDefault,
    );
    const [showOnlyUBDoS, setShowOnlyUBDoS] = useState<boolean>(false);

    useEffect(() => {
      setShowAutodorFindings(showAutodorFindingsDefault);
    }, [showAutodorFindingsDefault]);

    // default context passed to ag-grid fns; updated via gridData state/effect below
    // and accessed via <grid-event>.context.current (getter)
    const ctxRef = useRef<any>({
      claim,
      denialCodes: [],
      findingsMap: {},
      linesByFindingId: {},
      createFindingFunctions: {
        getCreateAuditFindingPropsFromSelectedRows: () => ({}),
        setCreatingAuditFinding,
        setDecliningAuditFinding,
        navigateToIbinRow,
        updateAuditFindingErrorHandler: null,
        readOnly,
        renderCount: 0,
        showFindings,
        userType,
        afclFilter: {},
        hideReviewStatus: false, // Hide review status is apparently Reports tab feature, n/a here
        authorId: user.id,
      },
    });
    // this is for debugging purposes -- to watch stale closures by logging the render count
    const render = useRef(0);
    render.current = render.current + 1;

    const [, hashParamBuilder] = useHashParams();

    const gridRef = useRef<AgGridReact<IBGridLine>>(null);
    const [isGridFirstRendered, setIsGridFirstRendered] = useState(false);
    const onGridReadyListenersRef = useRef<(() => void)[]>([]);

    const [scrollToRow, setScrollToRow] = useState<{
      ibLineId: string | null;
      findingId: string | null;
    }>();

    const [highlightRowId, setHighlightRowId] = useState<string | null>(null);

    useEffect(() => {
      // scrollToRow effect

      // if we unmount, we'll ignore the result:
      let ignoreResults = false;

      if (!scrollToRow?.ibLineId && !scrollToRow?.findingId) {
        return;
      }

      if (
        scrollToRow.ibLineId &&
        !scrollToRow?.findingId &&
        scrollToRow.ibLineId === highlightRowId
      ) {
        return; // already selected...
      }

      if (gridRef.current?.api && isGridFirstRendered) {
        const { findingId } = scrollToRow;
        let ibLineId = scrollToRow.ibLineId;
        if (!ibLineId && !!findingId) {
          ibLineId = gridData.gridContext.current.linesByFindingId[findingId];
        }

        if (!ibLineId) {
          void message.warning("Couldnt locate IB Line for finding.");
          return;
        }

        let rowNode: IRowNode<IBGridLine> | undefined = undefined;

        gridRef?.current?.api?.showLoadingOverlay();

        // async the row expansion to ensure available, and so we don't
        // get warning when ag-grid calls flushSync in render()
        void every(100, 10)
          .till(() => {
            rowNode = ignoreResults
              ? undefined
              : gridRef.current!.api.getRowNode(ibLineId);
            return !!rowNode;
          })
          .catch(() => {
            void (
              !ignoreResults &&
              message.error(`Could not find requested row (id: ${ibLineId})`)
            );
            gridRef?.current?.api?.hideOverlay();
          })
          .then(() => {
            if (rowNode && !ignoreResults) {
              gridRef?.current?.api.ensureNodeVisible(rowNode, "middle");
              if (rowNode.rowIndex) {
                gridRef.current!.api.setFocusedCell(
                  rowNode.rowIndex,
                  "description",
                );
              }
              rowNode.setExpanded(true);
              if (!findingId) {
                gridRef?.current?.api?.hideOverlay();
              } else {
                // as above, next tick since ag-grid calls flushSync
                let detailInfo: DetailGridInfo | undefined = undefined;
                void every(250, 20)
                  .till(() => {
                    detailInfo = ignoreResults
                      ? undefined
                      : gridRef.current!.api.getDetailGridInfo(
                          `detail_${ibLineId}`,
                        );

                    if (detailInfo) {
                      return true;
                    }
                  })
                  .catch(() => {
                    void (
                      !ignoreResults &&
                      message.error(
                        `Could not find requested row object (id: ${ibLineId})`,
                      )
                    );
                    gridRef?.current?.api?.hideOverlay();
                  })
                  .then(() => {
                    if (
                      !ignoreResults &&
                      detailInfo &&
                      typeof detailInfo === "object" &&
                      detailInfo?.api
                    ) {
                      const childRowNode =
                        detailInfo.api?.getRowNode(findingId);
                      if (childRowNode) {
                        detailInfo.api.ensureNodeVisible(childRowNode);
                        childRowNode.setExpanded(true);
                        detailInfo.api.flashCells({
                          rowNodes: [childRowNode],
                          flashDelay: 300,
                          fadeDelay: 4500,
                        });
                        gridRef?.current?.api?.hideOverlay();
                      } else {
                        log.warn(
                          "IbTable scrollToRowId called with invalid childRowId: ",
                          findingId,
                        );
                      }
                    }
                  })
                  .finally(() => gridRef?.current?.api?.hideOverlay());
              }
            }
          });
      }
      return () => {
        ignoreResults = true;
      };
    }, [scrollToRow, isGridFirstRendered]);

    useEffect(() => {
      if (isGridFirstRendered) {
        onGridReadyListenersRef.current.forEach((listener) => {
          listener();
        });
      }
    }, [isGridFirstRendered]);

    useImperativeHandle(
      ref,
      () => {
        return {
          onReady: (listener) => {
            onGridReadyListenersRef.current.push(listener);
            return () => {
              onGridReadyListenersRef.current =
                onGridReadyListenersRef.current.filter((l) => l !== listener);
            };
          },
          isGridReady: () => {
            return !!gridRef.current?.api && isGridFirstRendered;
          },
          scrollToRowId: (
            ibLineId: string | null,
            findingId: string | null,
          ) => {
            setScrollToRow({ ibLineId, findingId });
          },
        };
      },
      [isGridFirstRendered],
    );

    // improvement: display more info - looking up the findings from the grid data is complex here because
    // creating the error handler closures the current data, and ag-grid memoizes the error handler
    // so going this route, we'd need to use a state fn to update it when the deps change, but there's a
    // chicken-or-the-egg problem with passing this function to the gridData and using gridData as a dep...
    // something to redesign when we genericize the ag-grid usage
    const createFindingErrorHandler = () =>
      createUpdateAuditFindingErrorHandler(() => [
        <>Decline all other findings on this IB line to accept this finding.</>,
      ]);

    // callback used by 'create findings' button to get props for the new finding  // todo types!
    const getCreateAuditFindingPropsFromSelectedRows = useMemo(
      () => (selectedRows: IBGridLine[]) => ({
        auditFindingSeedType: "IB_CLAIM_LINE",
        updateAuditFindingErrorHandler: createFindingErrorHandler(),
        ibData: selectedRows,
        s3Key: "--ibdata--", // todo
      }),
      [],
    );

    // aggregate object of ibLines with findings mapped to lines, functions, to pass to grid
    const [gridData, setGridData] = useState<GridData>({
      ibLines: undefined,
      findings: {},
      gridContext: {
        get current() {
          return ctxRef.current;
        },
      },
      renderCount: 0,
      prevRenderCount: 0,
    });

    // get the list of denial code details for tag display
    const denialCodes = useDenialCodes();

    const { findingEditor } = useContext(FindingContext);

    useEffect(() => {
      log.trace("%cupdating finding editor: ", "color:orange", {
        fE: findingEditor,
      });
    }, [findingEditor]);

    // create the grid context object if we have the data we need
    useEffect(() => {
      gridRef?.current?.api?.showLoadingOverlay();

      const ibLinesLoaded =
        (claim && (claim?.itemizedBillLines?.length ?? 0) > 0) ||
        (!loading && !!claim);

      if (!(ibLinesLoaded && denialCodes.loaded && !!findingEditor)) {
        return;
      }

      const _ibLines: ItemizedBillLineOrNull[] = claim?.itemizedBillLines ?? [];
      if (!isNonNullIbLinesArray(_ibLines)) {
        log.warn(
          "ibLines is not an array of ItemizedBillLine or contains NULLs",
        );
        return;
      }

      const autodorFindings = !showAutodorFindings
        ? []
        : claim?.autodor?.autodorFindings ?? [];

      const { ibGridLines, findingsMap, linesByFindingId } = buildIbGridLines(
        _ibLines,
        claim?.findings ?? [],
        autodorFindings,
        denialCodes,
      );

      const ctx = {
        claim,
        ibLines: ibGridLines,
        findingsMap,
        linesByFindingId,
        denialCodes,
        findingEditor,
        createFindingFunctions: {
          getCreateAuditFindingPropsFromSelectedRows,
          setCreatingAuditFinding,
          setDecliningAuditFinding,
          updateAuditFindingErrorHandler: createFindingErrorHandler(),
          readOnly,
          renderCount: render.current,
          showFindings,
          userType,
          afclFilter: {},
          hideReviewStatus: false, // Hide review status is apparently Reports tab feature, n/a here
          authorId: user.id,
        },
      };

      ctxRef.current = ctx;

      setGridData((oldGridData) => {
        return {
          findings: findingsMap,
          ibLines: ibGridLines,
          claim,
          gridContext: {
            get current() {
              return ctxRef.current;
            },
          },
          renderCount: render.current,
          prevRenderCount: oldGridData.renderCount,
        };
      });
    }, [
      claim,
      denialCodes,
      readOnly,
      showFindings,
      showAutodorFindings,
      findingEditor,
    ]);

    const columnDefs = useMemo<ColDef<IBGridLine>[]>(
      () => getIbTableColumns(showFindings, readOnly),
      [showFindings, readOnly],
    );

    const onShowOnlyUBDosChange = useCallback(
      async (newValue?: boolean) => {
        if (gridRef.current) {
          if (newValue ?? showOnlyUBDoS) {
            await gridRef.current.api?.setColumnFilterModel("dateOfService", {
              values: getDateRange(
                batchClaim.dateOfServiceStart,
                batchClaim.dateOfServiceEnd,
              ),
            });
          } else {
            await gridRef.current.api.setColumnFilterModel(
              "dateOfService",
              null,
            );
          }
          gridRef.current.api?.onFilterChanged();
        }
      },
      [
        batchClaim.dateOfServiceEnd,
        batchClaim.dateOfServiceStart,
        showOnlyUBDoS,
      ],
    );

    const onFirstDataRendered = useCallback(() => {
      gridRef.current!.api.sizeColumnsToFit();
      onShowOnlyUBDosChange().catch((e) => {
        log.error("Error setting filter model", e);
      });
      setIsGridFirstRendered(() => true);
    }, [onShowOnlyUBDosChange]);

    const getRowId = useMemo<GetRowIdFunc>(() => {
      return (params: GetRowIdParams<IBGridLine>) => params.data?.id;
    }, []);

    const updateHashRowId = useCallback(
      (nodeId: string | undefined) => {
        if (nodeId) {
          hashParamBuilder
            .add("rowId", toSlug(nodeId) ?? false)
            .add("findingId", null)
            .commit();
          setHighlightRowId(nodeId);
        }
      },
      [gridData],
    );

    const onRowClicked = useCallback(
      (params: RowClickedEvent) => updateHashRowId(params.node.id),
      [gridData],
    );

    const onCellFocused = useCallback(
      (params: CellFocusedEvent) => {
        const focusedCell = params.api.getFocusedCell();
        const row = focusedCell
          ? params.api.getDisplayedRowAtIndex(focusedCell.rowIndex)
          : null;
        const nodeId = row ? row.id : undefined;
        updateHashRowId(nodeId);
      },
      [gridData],
    );

    const getRowStyle = useCallback<
      (params: RowClassParams<IBGridLine>) => RowStyle | undefined
    >((params) => {
      return params.node.id === highlightRowId
        ? { border: "1px solid gray" }
        : undefined;
    }, []);

    const isRowSelectable = useMemo<IsRowSelectable>(
      () => (params: IRowNode<IBGridLine>) =>
        readOnly || !showFindings ? false : acceptsFindings(params.data),
      [readOnly, showFindings],
    );

    const isRowMaster = useMemo<IsRowMaster>(
      () => (ibLine: IBGridLine) =>
        !showFindings
          ? false
          : (ibLine.findings?.length ?? 0) > 0 ||
            (ibLine.autodorFindings?.length ?? 0) > 0,
      [showFindings],
    );

    const sizeToFit = useCallback(() => {
      gridRef.current!.api.sizeColumnsToFit();
    }, []);

    const autoSizeAll = useCallback((skipHeader: boolean) => {
      const columnIds: string[] = [];
      gridRef.current!.api.getColumns()!.forEach((column) => {
        if (!column.getColDef().flex) {
          columnIds.push(column.getId());
        }
      });
      gridRef.current!.api.autoSizeColumns(columnIds, skipHeader);
    }, []);

    const onFilterTextBoxChanged = useCallback(
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        gridRef.current!.api.updateGridOptions({
          quickFilterText: e.target.value,
        });
      },
      [],
    );

    const loadingOverlayComponent = useMemo(() => {
      return LoadingOverlay;
    }, []);

    const noDataOverlayComponent = useMemo(() => {
      return NoDataOverlay;
    }, []);

    const rowClassRules = useMemo<RowClassRules<IBGridLine>>(
      () => getClaimLineTableRowClassRules(showFindings),
      [showFindings],
    );

    const ibTableSideBar = useMemo(() => getIbTableSideBar(isInternal), []);

    // passing in the context for the detail grid;
    // we can probably move the impl of `getDetailRowData` into the
    // returned params definition, seems like context can then be passed
    // from the renderer to that... bit circular tho, worth testing...
    const findingsDetailRendererProps = useMemo(() => {
      return createAuditFindingDetailsTableRendererParams({
        context: gridData.gridContext,
        getDetailRowData: (params: any) => {
          if (!params?.data) {
            log.warn("no params.data in getDetailRowData", params);
            params.successCallback([]);
            return;
          }
          const { findings, autodorFindings, ...ibLineWithoutFindings } =
            params.data;
          const combined = ((findings?.length ?? 0) > 0 ? findings : []).concat(
            autodorFindings ?? [],
          );
          if (combined.length < 1) {
            log.log("no findings data for ibLine @ getDetailRowData");
            params.successCallback([]);
            return;
          }

          const augmentedFindings: FindingDisplayAugmented[] = combined.map(
            (f: Finding) => {
              return {
                ...f,
                ibLine: ibLineWithoutFindings,
                ...(isAutodorFinding(f)
                  ? {
                      detailRowSummary: (
                        <>
                          <span tw="af-text-antd-lt-colorTextSecondary af-px-2 dark:af-text-antd-dk-colorTextSecondary af-text-xs">
                            Improper Units:
                          </span>
                          <span tw="af-text-antd-lt-colorPrimaryText dark:af-text-antd-dk-colorPrimaryText af-text-xs">
                            {f.improperUnits}
                          </span>
                          <span tw="af-text-antd-lt-colorTextSecondary af-px-2 dark:af-text-antd-dk-colorTextSecondary af-text-xs">
                            Improper Charge:
                          </span>
                          <span tw="af-text-antd-lt-colorPrimaryText dark:af-text-antd-dk-colorPrimaryText af-text-xs">
                            {asMoney(f.improperCharge)}
                          </span>
                          <span tw="af-text-antd-lt-colorTextSecondary af-px-2 dark:af-text-antd-dk-colorTextSecondary af-text-xs">
                            Type:
                          </span>
                          <span tw="af-text-antd-lt-colorPrimaryText dark:af-text-antd-dk-colorPrimaryText af-text-xs">
                            {getRuleLabelForFinding(f)}
                          </span>
                          {getRuleForAutodorFinding(f)?._type === "ptp" && (
                            <>
                              <span tw="af-text-antd-lt-colorTextSecondary af-px-2 dark:af-text-antd-dk-colorTextSecondary af-text-xs">
                                Col 1 code:
                              </span>
                              <span tw="af-text-antd-lt-colorPrimaryText dark:af-text-antd-dk-colorPrimaryText af-text-xs">
                                {getRuleForAutodorFinding(f)?.ptp?.colOneCode}
                              </span>
                            </>
                          )}
                        </>
                      ),
                    }
                  : {
                      detailRowSummary: (
                        <span tw="af-text-antd-lt-colorTextSecondary af-px-2 dark:af-text-antd-dk-colorTextSecondary af-text-xs">
                          {(f.improperPaymentReason ?? "").trim()}
                        </span>
                      ),
                    }),
              };
            },
          );
          params.successCallback(augmentedFindings ?? []);
        },
      });
    }, [gridData]);

    useEffect(() => {
      if (gridRef.current?.api) {
        //n.b. setRowData may affect updating cells since it's also bound to the ctrl:
        // --> gridRef.current!.api.setRowData(gridData.ibLines);  // not confirmed but suspect in re-render issues after finding change...
        // gridRef.current!.api.redrawRows();

        if (loading) {
          gridRef.current.api.showLoadingOverlay();
          return;
        } else {
          gridRef.current.api.hideOverlay();
        }

        if ((gridData?.ibLines?.length ?? 0) < 1) {
          gridRef.current.api.showNoRowsOverlay();
          return;
        } else {
          gridRef.current.api.hideOverlay();
        }

        gridRef.current.api.refreshCells();

        const to = setTimeout(() => {
          if (gridRef.current?.api) {
            gridRef.current.api.forEachDetailGridInfo(function (detailGridApi) {
              if (detailGridApi?.api) {
                detailGridApi.api!.redrawRows();
              }
            });
          }
        }, 0);
        return () => clearTimeout(to);
      }
    }, [gridData]);

    return (
      <>
        <ClaimLineTableStyleProvider {...{ width, height }}>
          <AgGridReact<IBGridLine>
            ref={gridRef}
            rowData={gridData.ibLines}
            columnDefs={columnDefs}
            defaultColDef={ibTableDefaultColumnDef}
            context={gridData.gridContext}
            enableCellTextSelection={true}
            ensureDomOrder={true}
            animateRows={true}
            rowHeight={44}
            rowBuffer={30}
            getRowId={getRowId}
            onFirstDataRendered={onFirstDataRendered}
            masterDetail={true}
            isRowMaster={isRowMaster}
            detailCellRendererParams={findingsDetailRendererProps}
            detailRowAutoHeight={true}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            isRowSelectable={isRowSelectable}
            onRowClicked={onRowClicked}
            onCellFocused={onCellFocused}
            sideBar={ibTableSideBar}
            statusBar={ClaimLineTableStatusBar}
            rowClassRules={rowClassRules}
            getRowStyle={getRowStyle}
            cacheQuickFilter={true}
            loadingOverlayComponent={loadingOverlayComponent}
            loadingOverlayComponentParams={{
              loadingMessage: "Loading IB data...",
            }}
            suppressCsvExport={!isAdmin}
            suppressExcelExport={!isAdmin}
            noRowsOverlayComponent={noDataOverlayComponent}
            noRowsOverlayComponentParams={{ noDataMessage: "No IB data found" }}
          />
        </ClaimLineTableStyleProvider>
        {tabToolbarRef.current &&
          createPortal(
            <div tw="af-flex af-items-center af-space-x-1">
              <div tw="af-flex af-items-center af-px-1">
                <div tw="af-text-xs af-pr-2 af-text-antd-lt-colorTextTertiary dark:af-text-antd-dk-colorTextTertiary">
                  Filter:
                </div>
                <Input
                  width={30}
                  tw="af-h-5 af-border af-border-antd-lt-colorBorderSecondary dark:af-border-antd-dk-colorBorderSecondary af-rounded af-text-xs focus:(af-outline-none af-ring-1 af-ring-offset-antd-lt-colorPrimaryBorder af-border-antd-lt-colorPrimaryBorder dark:(af-ring-antd-dk-colorPrimaryBorder af-border-antd-dk-colorPrimaryBorder))"
                  onChange={onFilterTextBoxChanged}
                />
              </div>
              <Tooltip title="Show only UB Dates of Service">
                <div tw="af-flex af-items-center af-px-1">
                  <div tw="af-text-xs af-pr-2 af-text-antd-lt-colorTextTertiary dark:af-text-antd-dk-colorTextTertiary af-whitespace-nowrap">
                    Only UB DoS:
                  </div>
                  <Switch
                    size="small"
                    checked={showOnlyUBDoS}
                    onChange={async () => {
                      const value = !showOnlyUBDoS;
                      setShowOnlyUBDoS(value);
                      await onShowOnlyUBDosChange(value);
                    }}
                  />
                </div>
              </Tooltip>

              {canViewAutodorFindings && (
                <Tooltip
                  title={
                    <>
                      {!claim ? (
                        <></>
                      ) : !claim?.autodor ? (
                        <div>Autodor has not processed this claim</div>
                      ) : (
                        <div>
                          {`Available Autodor suggestions: ${orZero(
                            claim?.autodor?.autodorFindings?.length,
                          )}`}
                        </div>
                      )}
                    </>
                  }
                >
                  <div tw="af-flex af-items-center af-px-1">
                    <div tw="af-text-xs af-pr-2 af-text-antd-lt-colorTextTertiary dark:af-text-antd-dk-colorTextTertiary">
                      Autodor:
                    </div>
                    <Switch
                      size="small"
                      data-cy="active-vs-historic-stats-switch"
                      css={css`
                        margin-left: 1%;
                      `}
                      disabled={
                        !(
                          claim &&
                          orZero(claim?.autodor?.autodorFindings?.length) > 0
                        )
                      }
                      defaultChecked={showAutodorFindingsDefault}
                      checked={showAutodorFindings}
                      onChange={(v) => {
                        setShowAutodorFindings(() => v);
                      }}
                    />
                  </div>
                </Tooltip>
              )}
              <Button
                css={css({
                  ...tw`af-h-6 af-w-6 af-p-0 af-rounded-md af-border-transparent`,
                })}
                onClick={() => sizeToFit()}
              >
                <VerticalAlignMiddleOutlined
                  style={{ color: "#888" }}
                  rotate={90}
                />
              </Button>
              <Button
                css={css({
                  ...tw`af-h-6 af-w-6 af-p-0 af-rounded-md af-border-transparent`,
                })}
                onClick={() => autoSizeAll(false)}
              >
                <ArrowsAltOutlined style={{ color: "#888" }} rotate={45} />
              </Button>
            </div>,
            tabToolbarRef.current,
          )}
      </>
    );
  },
);
