import type { Observable } from "rxjs";

import type {
  LoginChallengeResult,
  LoginStatusEvent,
  LogoutEvent,
} from "../../auth/types";
import type { User } from "../../remote/gql/graphql";
import { userQueryDoc } from "../../remote/queries/getUser.gql";
import type { ClientReturnType } from "../alaffiaClient";
import type { IAuthHandler } from "../authHandler";
import { AuthHandler } from "../authHandler";
import { ClientError } from "../error";
import type { AlaffiaClientModuleArgs } from "./modules";
import { AlaffiaClientModule } from "./modules";

interface AuthModuleArgs extends AlaffiaClientModuleArgs {
  authHandler?: IAuthHandler;
}

export class AuthModule extends AlaffiaClientModule {
  private authHandler: IAuthHandler;

  constructor(args: AuthModuleArgs) {
    super(args);
    this.authHandler = args.authHandler ?? new AuthHandler(this.apiUrl);
  }

  /**
   * Calls the configured api endpoint to login with the provided credentials.
   * If mfa is configured in the user's profile, the login will return a challenge response object.
   * To complete the login, the `loginChallenge` method must be called with the challenge response
   * and the mfa code.
   * If mfa is not configured, the login will return a boolean.
   *
   * @param username
   * @param password
   * @returns Promise<AuthLoginResult>
   */
  login = (input: {
    username: string;
    password: string;
  }): ReturnType<IAuthHandler["login"]> =>
    this.authHandler.login(input.username, input.password);

  /**
   * Verifies the code provided against the challenge response.
   * This function handles the second step of the MFA.
   * The `challengeResponse` be an object returned from the `login` method.
   *
   * @param challengeResponse
   * @param code
   * @returns
   */
  loginChallenge = (input: {
    challengeResponse: LoginChallengeResult;
    code: string;
  }): ReturnType<IAuthHandler["loginChallenge"]> =>
    this.authHandler.loginChallenge(input.challengeResponse, input.code);

  configureMfa: IAuthHandler["configureMfa"] = (configureMfaResult, code) =>
    this.authHandler.configureMfa(configureMfaResult, code);

  forceChangePassword: IAuthHandler["forceChangePassword"] = (
    forceChangePassword,
    oldPassword,
    newPassword,
  ) =>
    this.authHandler.forceChangePassword(
      forceChangePassword,
      oldPassword,
      newPassword,
    );

  forgotPassword = (input: {
    username: string;
  }): ReturnType<IAuthHandler["forgotPassword"]> =>
    this.authHandler.forgotPassword(input.username);

  confirmForgotPassword: IAuthHandler["confirmForgotPassword"] = (
    forgotPasswordResult,
    newPassword,
    code,
  ) =>
    this.authHandler.confirmForgotPassword(
      forgotPasswordResult,
      newPassword,
      code,
    );

  fetchAuthenticatedPost: IAuthHandler["fetchAuthenticatedPost"] = (
    resourcePath,
    bodyObject,
  ) => this.authHandler.fetchAuthenticatedPost(resourcePath, bodyObject);

  get loggedIn(): boolean {
    return this.authHandler.isLoggedIn();
  }

  get loginStatus(): Observable<LoginStatusEvent> {
    return this.authHandler.loginStatus;
  }

  get userId(): string | undefined {
    return this.authHandler.isLoggedIn()
      ? this.authHandler.getUserId()
      : undefined;
  }

  get username(): string | undefined {
    return this.authHandler.isLoggedIn()
      ? this.authHandler.getUsername()
      : undefined;
  }

  get token(): ReturnType<IAuthHandler["getToken"]> {
    return this.authHandler.getToken();
  }

  logout = (input: {
    event?: LogoutEvent;
  }): ReturnType<IAuthHandler["logout"]> =>
    this.authHandler.logout(
      input.event ?? { type: "LogoutEvent", trigger: "user" },
    );

  /**
   * returns the current user's information, based on the user id received
   * during login
   */
  getUser = async (): ClientReturnType<User> => {
    const id = this.authHandler.getUserId();
    if (!id) {
      throw new ClientError("Internal Error: no id for current user");
    }
    const result = await this.runGqlQuery(userQueryDoc, { id }, "getUser");
    return {
      data: result.data?.user ?? null,
      error: result.error ?? null,
    };
  };
}
