import { useEffect } from "react";
import type PDFViewCtrl from "@foxitsoftware/foxit-pdf-sdk-for-web-library-full/lib/PDFViewCtrl.full";

type ViewerEvents = PDFViewCtrl.constants.ViewerEvents;
type PDFViewer = PDFViewCtrl.PDFViewer;

export function usePDFViewerEvent<T extends unknown[]>(
  pdfViewer: PDFViewer | null,
  eventName: ViewerEvents | null | undefined,
  listener: (pdfViewer: PDFViewer, ...args: T) => void,
) {
  useEffect(() => {
    if (!pdfViewer || !eventName) {
      return;
    }
    const eventEmitter = pdfViewer.getEventEmitter();

    const eventListener = (...args: unknown[]) => {
      void listener(pdfViewer, ...(args as T));
    };

    eventEmitter.on(eventName, eventListener);

    return () => {
      eventEmitter.off(eventName, eventListener);
    };
  }, [eventName, listener, pdfViewer]);
}
