import type { PropsWithChildren } from "react";
import { useCallback, useEffect } from "react";
import { createPortal } from "react-dom";

import { cn } from "@alaffia-technology-solutions/tailwind-utils";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@alaffia-technology-solutions/ui";

import {
  SPLIT_VIEW_ANIMATION_DURATION,
  useSplitView,
} from "./SplitViewProvider";

interface SplitOverlayProps {
  show: boolean;
}

function SplitOverlay({
  children,
  show,
}: PropsWithChildren<SplitOverlayProps>) {
  const { closeAll, removeNodeLeft, removeNodeRight } = useSplitView();

  const handleEsc = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        closeAll();
      }
    },
    [closeAll],
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEsc);
    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, [show, handleEsc]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      data-state={show ? "open" : "closed"}
      className="af-fixed af-inset-0 af-z-10 af-bg-neutral-900/40 af-fill-mode-both data-[state=closed]:af-pointer-events-none data-[state=closed]:af-opacity-0 data-[state=open]:af-animate-in data-[state=closed]:af-fade-out-0 data-[state=open]:af-fade-in-0"
      style={{ transitionDuration: `${SPLIT_VIEW_ANIMATION_DURATION}ms` }}
      onClick={closeAll}
      onKeyDown={closeAll}
      onAnimationEnd={() => {
        if (!show) {
          removeNodeLeft();
          removeNodeRight();
        }
      }}
    >
      {children}
    </div>
  );
}

export function SplitView() {
  const {
    slotsState: { left, right },
    removeNodeLeft,
    removeNodeRight,
    closeLeft,
    closeRight,
  } = useSplitView();

  const isAnySlotInFullscreen =
    left.state === "fullscreen" || right.state === "fullscreen";
  const isAnySlotOpen = left.state === "open" || right.state === "open";
  const isLeftSlotFullscreen = left.state === "fullscreen";
  const isRightSlotFullscreen = right.state === "fullscreen";

  const showOverlay = isAnySlotOpen || isAnySlotInFullscreen;
  const showHandle = isAnySlotOpen && !isAnySlotInFullscreen;

  if (typeof window !== "undefined") {
    return createPortal(
      <>
        <SplitOverlay show={showOverlay} />

        {(left.node ?? right.node ?? isAnySlotInFullscreen) && (
          <ResizablePanelGroup
            direction="horizontal"
            className={cn(
              `af-fixed af-bottom-0 af-z-50`,
              isAnySlotInFullscreen && "af-max-h-screen",
              showHandle && "af-gap-3",
            )}
          >
            <ResizablePanel
              minSize={40}
              maxSize={70}
              defaultSize={50}
              className={cn(
                isRightSlotFullscreen && "!af-flex-[0_1_0px]",
                isLeftSlotFullscreen && "!af-flex-[100_1_0px]",
              )}
              onClick={() => left.state === "closed" && closeRight()}
            >
              {left.node && (
                <div
                  role="dialog"
                  data-state={left.state}
                  data-prev-state={left.prevState}
                  aria-hidden={left.state === "closed"}
                  className={cn(
                    `af-z-50 af-h-full af-overflow-hidden af-rounded-xl af-border-r af-border-t af-border-border af-bg-white af-shadow-lg af-transition af-ease-in af-fill-mode-both data-[state=closed]:af-duration-300 data-[state=open]:af-duration-300 data-[state=open]:af-animate-in data-[state=closed]:af-animate-out data-[state=closed]:af-slide-out-to-left data-[state=open][data-prev-state=closed]:af-slide-in-from-left`,
                  )}
                  onAnimationEnd={() =>
                    left.state === "closed" && removeNodeLeft()
                  }
                >
                  {left.node}
                </div>
              )}
            </ResizablePanel>

            {showHandle && <ResizableHandle withHandle={true} />}

            <ResizablePanel
              minSize={40}
              maxSize={70}
              defaultSize={50}
              className={cn(
                isRightSlotFullscreen && "!af-flex-[100_1_0px]",
                isLeftSlotFullscreen && "!af-flex-[0_1_0px]",
              )}
              onClick={() => right.state === "closed" && closeLeft()}
            >
              {right.node && (
                <div
                  role="dialog"
                  data-state={right.state}
                  data-prev-state={right.prevState}
                  aria-hidden={right.state === "closed"}
                  className={cn(
                    `af-z-50 af-h-full af-overflow-hidden af-rounded-tl-xl af-border-l af-border-t af-border-border af-bg-white af-shadow-lg af-transition af-ease-in af-fill-mode-both data-[state=closed]:af-duration-300 data-[state=open]:af-duration-300 data-[state=open]:af-animate-in data-[state=closed]:af-animate-out data-[state=closed]:af-slide-out-to-right data-[state=open][data-prev-state=closed]:af-slide-in-from-right`,
                  )}
                  onAnimationEnd={() =>
                    right.state === "closed" && removeNodeRight()
                  }
                >
                  {right.node}
                </div>
              )}
            </ResizablePanel>
          </ResizablePanelGroup>
        )}
      </>,
      document.body,
    );
  }
  return null;
}
