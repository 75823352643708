import type { HTMLAttributes } from "react";
import { memo } from "react";
import { LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { $convertFromMarkdownString, TRANSFORMERS } from "@lexical/markdown";
import type { InitialConfigType } from "@lexical/react/LexicalComposer";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";

import { cn } from "@alaffia-technology-solutions/tailwind-utils";

interface MarkdownLexicalComposerProps extends HTMLAttributes<HTMLDivElement> {
  markdown: string;
}

const markdownElementsClassName = [
  "[&_h1]:af-text-3xl",
  "[&_h2]:af-text-2xl",
  "[&_h3]:af-text-xl",
  "[&_h4]:af-text-lg",
  "[&_h5]:af-text-base",
  "[&_h6]:af-text-sm",
  "[&_ol]:af-list-inside",
  "[&_ol]:af-list-decimal",
  "[&_ul]:af-list-inside",
  "[&_ul]:af-list-disc",
].join(" ");

export const MarkdownLexicalComposer = memo(
  ({ className, markdown, ...props }: MarkdownLexicalComposerProps) => {
    const initialConfig = {
      namespace: "message",
      onError: console.error,
      editable: false,
      editorState: () => $convertFromMarkdownString(markdown, TRANSFORMERS),
      nodes: [HeadingNode, QuoteNode, LinkNode, ListNode, ListItemNode],
    } satisfies InitialConfigType;

    return (
      <LexicalComposer initialConfig={initialConfig} key={markdown}>
        <PlainTextPlugin
          contentEditable={
            <ContentEditable
              className={cn(
                "af-text-sm af-text-neutral-900",
                markdownElementsClassName,
                className,
              )}
              {...props}
            />
          }
          placeholder={null}
          ErrorBoundary={LexicalErrorBoundary}
        />
      </LexicalComposer>
    );
  },
);
