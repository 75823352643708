/* eslint-disable @typescript-eslint/prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Modal, Select, Tooltip, Upload } from "antd";
import { gql, useMutation, useQuery } from "urql";

import { UserContext } from "../../../context/user";
import { setWorkflowStateMutation } from "../workflow";
import { toFirstLetterUpper, uploadFile } from "./documentUploadUtil";

const documentUploadKinds = {
  MEDICAL_RECORD: {
    FACE_SHEET: "Face Sheet",
    THERAPY_TREATMENT_PLAN_WITH_NOTES: "Therapy Treatment Plan And Notes",
    MEDICATION_ADMINISTRATION_RECORDS: "Medication Administration Records",
    DISCHARGE_SUMMARY: "Discharge Summary",
    PHYSICIAN_PROGRESS_NOTES: "Physician Progress Notes ",
    HISTORY_AND_PHYSICAL: "History & Physical ",
    LABORATORY_REPORTS: "Laboratory Reports",
    EMERGENCY_ROOM_RECORDS: "Emergency Room Records",
    RADIOLOGY_REPORTS: "Radiology Reports & Images",
    NURSING_NOTES: "All Nursing Notes",
    OPERATIVE_REPORTS: "Operative Reports",
    CONSULTATIONS: "Consultations (RT/PT/OT/ST, etc)",
    PATHOLOGY_REPORTS: "Pathology Reports",
  },
  BILLING_RECORD: {
    UB04_CLAIM_FORM: "UB-04 Claim Form",
    ITEMIZED_BILL: "Itemized Bill",
  },
  OTHER: "Other Supporting Documentation",
};

// helper function to manually set a claim into IN_PROGRESS if it is In the COMPLETED state already
// Ran after documentUploads
const forceWorkflowStateIntoInProgress = async (
  batchClaim: any,
  setWorkflowState: any,
) => {
  const {
    batchClaimState: { workflowClaimState },
  } = batchClaim;
  if (workflowClaimState === "COMPLETED") {
    console.log(
      "Setting the claim in workflow 'COMPLETED' state to 'IN_PROGRESS' after file upload...",
    );
    const { error } = await setWorkflowState({
      workflowState: "IN_PROGRESS",
      batchClaimIds: [batchClaim.id],
    });
    if (error)
      return {
        error: true,
        message:
          "Claim is in the COMPLETED state and a doc was uploaded --> Error occured in setting that claim back into IN_PROGRESS",
      };

    return {
      error: false,
      message:
        "Claim is in the COMPLETED state and a doc was uploaded --> Claim was succesfully set back into IN_PROGRESS",
    };
  }
  return {
    error: false,
    message:
      "Claim is NOT in the COMPLETED state and a doc was uploaded --> no further action is taken",
  };
};

const SupportingDocumentUploadModal = ({
  batchClaimId,
  visible,
  setVisible,
  claimListDataFragment,
}: any) => {
  const user = React.useContext(UserContext);
  const [loading, setLoading] = React.useState(false);
  const [uploadList, setUploadList] = React.useState<any>([]);

  // (AT) We need an asyncronous refresh to be able to update the
  // UI when a document is uploaded. This is entirely async because we
  // are uploading directly to s3 and have no notification when internal graphql
  // is done processing the request (remember that s3 triggers the documentUploadHandler lambda)
  // In production the whole loop takes a few seconds,
  // but dev is much slower (~10seconds) - so
  // this function may not actually return any new data in a dev environment.
  // but a refresh of the page will show the new uploaded files
  const asyncRefresh = async (cb: any) => {
    const wait = () =>
      new Promise((resolve) => {
        setTimeout(resolve, 4000);
      });

    // send 4 update requests - 4 seconds apart
    let counter = 0;
    while (counter < 4) {
      await wait();
      cb();
      counter++;
    }
  };

  // s3AuditDocumentations used here for performance reasons, but
  // ideally we should be using the auditDocumentation custom resolver
  // claimListDataFragment is defined at the user context
  const batchClaimSupportingDocsQuery = gql`
    query SupportingDocumentUploadModal_batchClaim($batchClaimId: UUID!) {
      batchClaim(id: $batchClaimId) {
        ...claimListDataFragment
      }
    }
    ${claimListDataFragment}
    # includes workflowClaimState & documentationClaimState
  `;

  const [{ fetching, data }, refetchQuery] = useQuery({
    query: batchClaimSupportingDocsQuery,
    variables: { batchClaimId },
    pause: batchClaimId === null,
    requestPolicy: "network-only",
  });

  const { batchClaim } = (!fetching && data) || {
    batchClaim: {},
  };

  const [, setWorkflowState] = useMutation(setWorkflowStateMutation);

  // Returns a promise which is used to block the upload of files until the ok button is hit,
  // allowing us to manage the uploadList
  const addFileToUploadList = (_file: any, fileList: any) => {
    // Update the uploadList, handling duplicates
    const newList = fileList.filter(
      (f: any) => uploadList.findIndex((u: any) => u.name === f.name) === -1,
    );
    setUploadList([...uploadList, ...newList]);
    // Return false to <Upload> since we are managing our own list state and upload
    return false;
  };

  // Upload entry point for all files in the list
  const uploadFilesHandler = async () => {
    if (uploadList.length === 0) return;

    setLoading(true);

    // Show individual file uploading
    setUploadList(
      uploadList
        .filter((f: any) => f.status !== "success")
        .map((f: any) => {
          f.status = "uploading";
          return f;
        }),
    );

    // Wait for all of the uploads to resolve/reject
    const completed = await Promise.allSettled(
      // Filter out uploads that have already succeeded
      uploadList
        .filter((f: any) => f.status !== "success")
        .map(async (file: any) =>
          uploadFile({
            file,
            batchClaimId,
            userId: user.id,
          })
            .then((success) => {
              void message.success(`${file.name} uploaded`);
              return Promise.resolve(success);
            })
            .catch((error) => {
              console.error("error uploading documents", error);
              void message.error(
                `Upload failed for ${file.name}${
                  error.error ? ` -- ${error.error}` : ""
                }`,
              );
              return Promise.reject({ file, error });
            }),
        ),
    );

    // if at least one upload succeeds
    if (
      completed.filter((promise) => promise.status === "fulfilled").length > 0
    ) {
      // If claim is Complete this means claim is back in In-Progress
      // If this claim is from MDH the PRS/Admin will set this claim to disputed manually
      const { message, error } = await forceWorkflowStateIntoInProgress(
        batchClaim,
        setWorkflowState,
      );
      if (error) {
        console.error(message);
      } else {
        console.log(message);
      }
    }

    setLoading(false);

    // Map the promises back to files with the upload status
    setUploadList([
      ...completed.map((promise) => {
        if (promise.status === "fulfilled") {
          const file = promise.value;
          file.status = "success";
          return file;
        } else {
          const { file, error } = promise.reason;
          if (!file) {
            void message.error(
              "Critical Error. Please log out and log back in",
            );
            return {
              status: "error",
              name: "error",
              message: "critical error",
              // FIXME this needs to be set
              uid: "",
            };
          }
          file.status = "error";
          file.message = error.message;
          return file;
        }
      }),
    ]);
  };

  // Upload List Item custom renderer
  const uploadListItem = (itemNode: any, file: any) => {
    const errorNode = (
      <Tooltip
        title={file.message || "Upload Error"}
        getPopupContainer={() => document.body}
      >
        {itemNode.props.children}
      </Tooltip>
    );
    return (
      <div>
        {file.status === "error" ? errorNode : itemNode}
        <Select
          data-id="document-type-select"
          placeholder="Select document type"
          size="small"
          listItemHeight={22}
          dropdownMatchSelectWidth={false}
          options={Object.keys(documentUploadKinds).map((key) => ({
            label: toFirstLetterUpper(key),
            value: key,
          }))}
          onSelect={(documentUploadKind) => {
            // Set the documentUploadKind for this file in the list
            setUploadList([
              ...uploadList.map((f: any) => {
                if (f.name === file.name)
                  f.documentUploadKind = documentUploadKind;
                return f;
              }),
            ]);
          }}
        />
      </div>
    );
  };

  return (
    <Modal
      title={"Upload Documentation"}
      onCancel={() => {
        setUploadList([]);
        setVisible(false);
      }}
      onOk={() => {
        if (uploadList.filter((f: any) => f.status !== "success").length > 0) {
          void uploadFilesHandler();
          // refresh the claim information
          setVisible(false);
          void asyncRefresh(refetchQuery);
        } else {
          setUploadList([]);
          setVisible(false);
        }
      }}
      confirmLoading={loading || fetching}
      open={visible}
    >
      <div style={{ padding: "2%" }}>
        <p>Examples of Supporting Documentation (scroll for more):</p>
        <div style={{ maxHeight: "100px", overflow: "scroll" }}>
          {Object.entries(documentUploadKinds).map(([key, value], i) => (
            <section key={i}>
              <h3 style={{ fontSize: "16px" }}>{toFirstLetterUpper(key)}</h3>
              <ul>
                {typeof value === "object" ? (
                  Object.values(value).map((docKindDesc, index) => (
                    <li key={index}>{docKindDesc}</li>
                  ))
                ) : (
                  <li key={i}>{value}</li>
                )}
              </ul>
            </section>
          ))}
        </div>
      </div>
      <Upload.Dragger
        name="file"
        id="document-upload"
        accept=".pdf"
        multiple
        showUploadList={{ showRemoveIcon: true }}
        onRemove={(file) =>
          setUploadList(uploadList.filter((f: any) => f.name !== file.name))
        }
        beforeUpload={addFileToUploadList}
        itemRender={uploadListItem}
        fileList={uploadList}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        {uploadList.length === 0 && (
          <>
            <p className="ant-upload-hint">
              Please submit applicable components of the medical record and/or
              other documentation you deem appropriate to support payment of the
              attached claims.
            </p>
            <span>
              <p style={{ fontWeight: "bold" }}>
                You can upload docs for any claim on this page, regardless of
                status
              </p>
            </span>
          </>
        )}
        {uploadList.length > 0 && (
          <>
            <p className="ant-upload-hint">
              Mouse over an item and click its trash can to remove it from the
              list.
            </p>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-expect-error */}
            <p className={{ fontWeight: "bold" }}>
              Click OK to start uploading
            </p>
          </>
        )}
      </Upload.Dragger>
    </Modal>
  );
};

export { SupportingDocumentUploadModal, forceWorkflowStateIntoInProgress };
