import React from "react";
import {
  IconChevronDown,
  IconChevronUp,
  IconDots,
  IconFileTypePdf,
  IconThumbDown,
  IconThumbDownFilled,
  IconThumbUp,
  IconThumbUpFilled,
} from "@tabler/icons-react";
import type { ColDef } from "ag-grid-community";
import type { CustomCellRendererProps } from "ag-grid-react";
import { AgGridReact } from "ag-grid-react";

import { useEventCallback } from "@alaffia-technology-solutions/hooks";
import { cn } from "@alaffia-technology-solutions/tailwind-utils";
import { Button, Separator } from "@alaffia-technology-solutions/ui";

import type { Response, ResponsePage } from "./../Chatbox/types";
import { PromptResponseRatingType } from "./../Chatbox/types";
import { DateTime } from "./../DateTime";
import { MarkdownLexicalComposer } from "./MarkdownLexicalComposer";

interface CollapsibleTableProps {
  columnDefs: ColDef<ResponsePage>[];
  message: Response | null;
}
const CollapsibleTable = React.forwardRef<
  HTMLDivElement,
  CollapsibleTableProps
>(({ columnDefs, message }, ref) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const pages = React.useMemo(() => message?.pages ?? [], [message?.pages]);
  const visiblePages = React.useMemo(
    () => (isOpen ? pages : pages.slice(0, 3)),
    [isOpen, pages],
  );

  return (
    <div
      className="af-w-full af-rounded-md af-border-[1px] af-border-border"
      ref={ref}
    >
      <div className="ag-theme-quartz af-max-h-[300px] af-w-full af-overflow-auto">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={visiblePages}
          domLayout="autoHeight"
          headerHeight={28}
          rowHeight={48}
        />
      </div>
      {pages.length > 3 && (
        <CollapsibleTableTrigger
          message={message}
          isOpen={isOpen}
          onClick={() => setIsOpen((prev) => !prev)}
        />
      )}
    </div>
  );
});

interface CollapsibleTableTriggerProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  message: Response | null;
  isOpen: boolean;
}
const CollapsibleTableTrigger = React.forwardRef<
  HTMLButtonElement,
  CollapsibleTableTriggerProps
>(({ className, message, isOpen, ...props }, ref) => {
  return (
    <button
      className={cn(
        "af-w-full af-text-center af-text-xs af-text-gray-500",
        className,
      )}
      ref={ref}
      {...props}
    >
      <span className="af-flex af-items-center af-justify-center af-py-2">
        {!isOpen ? (
          <>
            Show All Results ({message?.pages?.length ?? 0})
            <IconChevronDown className="af-ml-2 af-h-4 af-w-4" />
          </>
        ) : (
          <>
            Collapse
            <IconChevronUp className="af-ml-2 af-h-4 af-w-4" />
          </>
        )}
      </span>
    </button>
  );
});

export type OnRate = (
  currentUserRating: PromptResponseRatingType | null,
  messageId: string,
) => void;

type AnswerItemProps = React.PropsWithChildren<
  React.HTMLAttributes<HTMLLIElement> & {
    message: Response | null;
    onDownRate?: OnRate;
    onFilePreview?: (id: string, name: string, page: number) => void;
    onUpRate?: OnRate;
  }
>;

export const AnswerItem = React.forwardRef<HTMLLIElement, AnswerItemProps>(
  (
    { message, className, onDownRate, onFilePreview, onUpRate, ...props },
    ref,
  ) => {
    const onFilePreviewCallback = useEventCallback(onFilePreview);
    const columnDefs = React.useMemo<ColDef<ResponsePage>[]>(
      () => [
        {
          field: "pageNumber",
          headerName: "Page Number",
          width: 128,
          tooltipField: "file.fileName",
          resizable: false,
          cellRenderer: ({
            data,
            value,
          }: CustomCellRendererProps<ResponsePage>) => (
            <Button
              variant="link"
              className="af-w-full af-justify-start af-font-normal"
              onClick={() => {
                if (!data) {
                  return;
                }
                onFilePreviewCallback(
                  data.file.id,
                  data.file.fileName,
                  data.pageNumber,
                );
              }}
            >
              {value}
            </Button>
          ),
        },
        {
          field: "textMatched",
          headerName: "Source",
          tooltipField: "textMatched",
          resizable: false,
          flex: 1,
        },
      ],
      [onFilePreviewCallback],
    );

    return (
      <li
        className={cn(
          "af-flex af-flex-col af-items-start af-rounded-lg af-bg-gray-200 af-p-6",
          className,
        )}
        ref={ref}
        {...props}
      >
        <div className="af-flex af-w-full af-flex-col af-gap-2">
          <div className="af-flex af-items-center af-gap-2 af-text-xs af-text-gray-700">
            Answer <span>|</span>{" "}
            {message?.createdAt ? (
              <DateTime date={message.createdAt} icon={null} />
            ) : (
              <span>...</span>
            )}
          </div>
          <div className="af-flex af-items-center af-gap-2 af-overflow-auto af-whitespace-nowrap af-text-xs af-text-gray-600">
            Files{" "}
            {message?.contextFiles?.map((file) => (
              <span key={file.id}>
                <Button
                  key={file.id}
                  variant="outline"
                  size="sm"
                  className="af-gap-2 af-border-[1px] af-border-primary-200 af-bg-primary-100 af-p-[6px] af-text-xs af-font-medium af-text-neutral-900"
                  onClick={() => onFilePreview?.(file.id, file.fileName, 1)}
                >
                  <IconFileTypePdf className="af-h-4 af-w-4 af-text-gray-500" />
                  {file.fileName}
                </Button>
              </span>
            ))}
          </div>
          {message?.responseText ? (
            <MarkdownLexicalComposer markdown={message.responseText} />
          ) : (
            <div className="af-align-end af-flex af-gap-0.5 af-text-sm af-text-gray-600">
              Autodor is thinking <IconDots className="animate-pulse" />{" "}
            </div>
          )}
        </div>
        {(message?.pages?.length ?? 0) > 0 && (
          <>
            <Separator className="af-my-8 af-w-full" />
            <CollapsibleTable columnDefs={columnDefs} message={message} />
          </>
        )}
        {message?.ratings && (
          <>
            <Separator className="af-my-6" />
            <div className="af-flex af-items-center af-justify-end af-gap-4 af-text-xs af-text-gray-600">
              <button
                aria-label="Rate up"
                className="af-flex af-min-w-10 af-items-center af-gap-1"
                onClick={() =>
                  onUpRate &&
                  onUpRate(
                    message.ratings?.currentUserRating ?? null,
                    message.id,
                  )
                }
              >
                {message.ratings?.currentUserRating ===
                PromptResponseRatingType.UP ? (
                  <IconThumbUpFilled className="af-text-primary-500" />
                ) : (
                  <IconThumbUp />
                )}
                <span
                  className={cn(
                    "af-text-base",
                    message.ratings?.currentUserRating ===
                      PromptResponseRatingType.UP && "af-text-primary-500",
                  )}
                >
                  {message.ratings.upRatings}
                </span>
              </button>
              <button
                aria-label="Rate down"
                className="af-flex af-min-w-10 af-items-center af-gap-1"
                onClick={() =>
                  onDownRate &&
                  onDownRate(
                    message.ratings?.currentUserRating ?? null,
                    message.id,
                  )
                }
              >
                {message.ratings?.currentUserRating ===
                PromptResponseRatingType.DOWN ? (
                  <IconThumbDownFilled className="af-text-primary-500" />
                ) : (
                  <IconThumbDown />
                )}
                <span
                  className={cn(
                    "af-text-base",
                    message.ratings?.currentUserRating ===
                      PromptResponseRatingType.DOWN && "af-text-primary-500",
                  )}
                >
                  {message.ratings.downRatings}
                </span>
              </button>
            </div>
          </>
        )}
      </li>
    );
  },
);
