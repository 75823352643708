import {
  createBasicMutationHook,
  createQueryHook,
  createSubscriptionDataHook,
  createSubscriptionHook,
} from "../factory";

export const userKeys = {
  all: ["users"] as const,
  details: () => [...userKeys.all, "detail"] as const,
};

export const auth = {
  getUser: createQueryHook({
    methodSelector: (alaffiaClient) => alaffiaClient.auth.getUser,
    queryKeyFn: userKeys.details,
  }),
  login: createBasicMutationHook({
    methodSelector: (alaffiaClient) => alaffiaClient.auth.login,
    mutationKey: ["auth", "login"],
  }),
  loginChallenge: createBasicMutationHook({
    methodSelector: (alaffiaClient) => alaffiaClient.auth.loginChallenge,
    mutationKey: ["auth", "loginChallenge"],
  }),
  forgotPassword: createBasicMutationHook({
    methodSelector: (alaffiaClient) => alaffiaClient.auth.forgotPassword,
    mutationKey: ["auth", "forgotPassword"],
  }),
  logout: createBasicMutationHook({
    methodSelector: (alaffiaClient) => alaffiaClient.auth.logout,
    mutationKey: ["auth", "logout"],
  }),
  userId: createSubscriptionDataHook({
    observableSelector: (alaffiaClient) => alaffiaClient.auth.loginStatus,
    transformResult: (result) => {
      if (result.data?.type === "LoginSuccessResult") {
        return result.data.userId;
      }
      return null;
    },
  }),
  loggedIn: createSubscriptionDataHook({
    observableSelector: (alaffiaClient) => alaffiaClient.auth.loginStatus,
    transformResult: (result) => result.loggedIn,
  }),
  loginStatus: createSubscriptionHook({
    observableSelector: (alaffiaClient) => alaffiaClient.auth.loginStatus,
  }),
};
