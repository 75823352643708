"use client";

import type { HTMLAttributes } from "react";
import { forwardRef, memo, useImperativeHandle, useRef } from "react";

import { cn } from "@alaffia-technology-solutions/tailwind-utils";
import { Loader } from "@alaffia-technology-solutions/ui";

import { usePDFStore } from "../../providers";
import { PDFErrorMessage } from "./components/pdf-error-message";
import { useInitializePDFCtrl } from "./hooks/useInitializePDFCtrl";
import { usePDFViewerEvent } from "./hooks/usePDFViewerEvent";

type PDFViewerCanvasProps = HTMLAttributes<HTMLDivElement>;

export const PDFViewerCanvas = memo(
  forwardRef<HTMLDivElement, PDFViewerCanvasProps>(({ className }, ref) => {
    const elementRef = useRef<HTMLDivElement>(null);
    const { pdfViewerCtrl } = useInitializePDFCtrl(elementRef);
    const { data: error } = usePDFStore((state) => state.error);

    useImperativeHandle(
      ref,
      () => {
        if (!elementRef.current) {
          throw new Error("elementRef is not set");
        }

        return elementRef.current;
      },
      [elementRef],
    );

    const pdfViewer = pdfViewerCtrl?.pdfViewer ?? null;

    usePDFViewerEvent(
      pdfViewer,
      pdfViewerCtrl?.events?.pageNumberChange,
      (_pdfViewer, pageIndex: number) => {
        pdfViewerCtrl?.setCurrentPageIndex(pageIndex - 1);
      },
    );

    const showLoader =
      !pdfViewer || pdfViewerCtrl?.pdfViewerState === "pending";

    return (
      <>
        {error ? (
          <PDFErrorMessage error={error} />
        ) : (
          <div
            className={cn(
              "af-flex af-h-full af-w-full af-min-w-0 af-overflow-auto",
              className,
            )}
          >
            {showLoader && (
              <div
                ref={elementRef}
                className="af-relative af-flex af-w-full af-items-center af-justify-center af-bg-primary-100"
              >
                <Loader className="af-h-16 af-w-16" />
              </div>
            )}
            <div ref={elementRef} className="relative" />
          </div>
        )}
      </>
    );
  }),
);

PDFViewerCanvas.displayName = "PDFViewerCanvas";
